import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/supplychain/notifications/store/reducers';
import * as Actions from 'app/main/supplychain/notifications/store/actions';
import Bell2Icon from '@stracker/customicons/Bell2Icon';

const StyledBadge = withStyles(theme => ({
	badge: {
		right: 0,
		top: 2,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px'
	}
}))(Badge);

function NotifIcon(props) {
	const dispatch = useDispatch();

	const userLog = useSelector(({ auth }) => auth.user);
	const notification = useSelector(state => state.notificationsData.notificationsData);

	const [notifUnread, setnotifUnread] = React.useState(0);
	const interval = React.useRef(null);

	useLayoutEffect(() => {
		if (notification.status === 'INIT') {
			dispatch(Actions.getNotifications(userLog.id, userLog.data.idCustomer, 0, userLog.data.idRole));
			dispatch(
				Actions.getNotificationsTransportCotation(userLog.id, userLog.data.idCustomer, userLog.data.idRole)
			);
		}
		if (notification.status === 'ok') {
			setnotifUnread(notification.totalUnread);
		} else {
			setnotifUnread(notification.totalUnread);
		}
		// eslint-disable-next-line
	}, [notification]);

	const reloadNotifications = useCallback(() => {
		dispatch(Actions.getNotifications(userLog.id, userLog.data.idCustomer, 0, userLog.data.idRole));
		dispatch(Actions.getNotificationsTransportCotation(userLog.id, userLog.data.idCustomer, userLog.data.idRole));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		interval.current = setInterval(reloadNotifications, 60000);

		return () => {
			clearInterval(interval.current);
		};
	}, [reloadNotifications]);

	return (
		<StyledBadge
			color="error"
			invisible={notifUnread === 0}
			badgeContent={notifUnread}
			className="list-item-icon"
			overlap="circle"
			max={50}
		>
			{props.children}
		</StyledBadge>
	);
}

NotifIcon.defaultProps = {
	children: <Bell2Icon />
};

export default withReducer('notificationsData', reducer)(NotifIcon);
