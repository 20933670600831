import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Formsy from 'formsy-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import * as Actions from './store/actions';
import AddEmail from './inc/AddEmail';
import AddSms from './inc/AddSms';
import { ButtonStyled } from '../../../../../@stracker/components/StyledComponents/ButtonStyled';
import Switch from '@stracker/components/Switch/Switch';

function NotificationsContent(props) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { contrastTextLight } = theme.palette.stracker;
	const { t } = useTranslation('notifications');
	const formRef = useRef(null);

	const user = useSelector(({ auth }) => auth.user);
	const userId = user.id;
	const { idCustomer } = user.data;

	const notificationsEmail = useSelector(({ notifications }) => notifications.notifications.notificationsEmail);
	const notificationsSms = useSelector(({ notifications }) => notifications.notifications.notificationsSms);
	const preferedNotif = useSelector(({ notifications }) => notifications.notifications.preferedNotif);

	const [isFormValid, setIsFormValid] = useState(false);

	const [notifEmail, setNotifEmail] = useState([{ contactId: '', contactType: 'email' }]);
	const [notifSms, setNotifSms] = useState([{ contactId: '', contactType: 'sms' }]);
	const [prefNotif, setPrefNotif] = useState({
		depositDocument: false,
		fashionHubContact: false,
		quotationCreate: false,
		quotationQuote: false,
		transportCreated: false
	});

	React.useEffect(() => {
		dispatch(Actions.getPreferedNotifications(idCustomer, userId));
		dispatch(Actions.getNotificationsEmail(idCustomer));
		dispatch(Actions.getNotificationsSms(idCustomer));
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (notificationsEmail) {
			setNotifEmail(notificationsEmail);
		}
	}, [notificationsEmail]);

	useEffect(() => {
		if (notificationsSms) {
			setNotifSms(notificationsSms);
		}
	}, [notificationsSms]);

	useEffect(() => {
		if (preferedNotif) {
			setPrefNotif({ ...preferedNotif });
		}
	}, [preferedNotif]);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function handleSubmit() {
		const addList = notifEmail.concat(notifSms);

		dispatch(Actions.updateNotification(addList, idCustomer, userId));
	}

	const handleUpdateNotif = async (name, value) => {
		const prefNotifCpy = {
			depositDocument: name === 'DEPOSIT_DOCUMENT' ? value : prefNotif.depositDocument,
			fashionHubContact: name === 'FASHION_HUB_CONTACT' ? value : prefNotif.fashionHubContact,
			quotationCreate: name === 'QUOTATION_CREATE' ? value : prefNotif.quotationCreate,
			quotationQuote: name === 'QUOTATION_QUOTE' ? value : prefNotif.quotationQuote,
			transportCreated: name === 'TRANSPORT_CREATED' ? value : prefNotif.transportCreated
		};
		setPrefNotif({ ...prefNotifCpy });
		await dispatch(Actions.updatePrefNotif(idCustomer, name, value, userId));
		await dispatch(Actions.getPreferedNotifications(idCustomer, userId)); // Pas nécessaire
	};

	return (
		<div>
			<div
				className="flex items-center mb-10 pb-10 border-b-1"
				style={{ borderColor: theme.palette.stracker.colorOutlined }}
			>
				<p id="message-form" style={{ color: contrastTextLight }}>
					{t('DIALOG_NOTIFICATION')}
				</p>
			</div>
			<h5 className="mb-5 text-16" style={{ color: contrastTextLight }} id="message-form">
				{t('NOTIF_TYPES')}
			</h5>
			<div style={{ marginBottom: '10px' }}>
				<div className="flex" style={{ marginBottom: '5px' }}>
					<div
						onClick={() => {
							handleUpdateNotif('QUOTATION_CREATE', !prefNotif?.quotationCreate);
						}}
						className="flex items-center w-1/2"
					>
						<Switch
							isOn={prefNotif && prefNotif?.quotationCreate}

							//handleToggle={async () => {}}
						/>
						<p style={{ marginLeft: '5px' }}>{t('QUOTATION_CREATE')}</p>
					</div>
					<div
						onClick={() => {
							handleUpdateNotif('QUOTATION_QUOTE', !prefNotif?.quotationQuote);
						}}
						className="flex items-center w-1/2"
					>
						<Switch isOn={prefNotif && prefNotif?.quotationQuote} />
						<p style={{ marginLeft: '5px' }}>{t('SEND_QUOTATION')}</p>
					</div>
				</div>
				<div className="flex" style={{ marginBottom: '5px' }}>
					<div
						onClick={() => {
							handleUpdateNotif('TRANSPORT_CREATED', !prefNotif?.transportCreated);
						}}
						className="flex items-center w-1/2"
					>
						<Switch isOn={prefNotif && prefNotif?.transportCreated} />
						<p style={{ marginLeft: '5px' }}>{t('TRANSPORT_CREATED')}</p>
					</div>
					<div
						onClick={() => {
							handleUpdateNotif('FASHION_HUB_CONTACT', !prefNotif?.fashionHubContact);
						}}
						className="flex items-center w-1/2"
					>
						<Switch isOn={prefNotif && prefNotif?.fashionHubContact} />
						<p style={{ marginLeft: '5px' }}>{t('FASHION_HUB')}</p>
					</div>
				</div>
				<div
					onClick={async () => {
						handleUpdateNotif('DEPOSIT_DOCUMENT', !prefNotif?.depositDocument);
					}}
					className="flex items-center w-1/2"
				>
					<Switch isOn={prefNotif && prefNotif?.depositDocument} />
					<p style={{ marginLeft: '5px' }}>{t('DEPOSIT_DOCUMENT')}</p>
				</div>
			</div>

			<Formsy
				ref={formRef}
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				className="flex flex-col justify-center w-full"
			>
				<h5 className="mb-5 text-16" style={{ color: contrastTextLight }} id="message-form">
					{t('NOTIFEMAIL')}
				</h5>
				<AddEmail hangleNotification={setNotifEmail} email={notifEmail} Custo={idCustomer} />
				<h5 className="mb-5 text-16" style={{ color: contrastTextLight }} id="message-form">
					{t('NOTIFSMS')}
				</h5>
				<AddSms hangleNotification={setNotifSms} sms={notifSms} Custo={idCustomer} />
				<div className="flex w-full justify-end mr-10">
					<ButtonStyled className="ml-5 py-7 px-10 mr-9" disabled={!isFormValid} type="submit">
						{t('CREATE')}
					</ButtonStyled>
				</div>
			</Formsy>
		</div>
	);
}

export default NotificationsContent;
