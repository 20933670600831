import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTheme } from '@material-ui/core/styles';
import { TitleTabSettings } from '@stracker/components/title/TitleTabSettings';
import { ButtonStyled } from '@stracker/components/StyledComponents/ButtonStyled';
import UsersIconBlue from '../../../custom-icons/UsersIconBlue';
import CircleAddIcon from '@stracker/customicons/CircleAddIcon';

function MembresHeader(props) {
	const theme = useTheme();
	const { t } = useTranslation('membres');

	const user = useSelector(({ auth }) => auth.user);
	const memberTotalList = useSelector(({ members }) => members.member);

	function addList(BackToListAdd) {
		BackToListAdd('AddMembers');
	}

	return (
		<>
			<div className="block sm:hidden w-full flex-col">
				<div
					className={clsx(
						props.switchPageValue === 'AddMembers' && 'mr-3.5',
						'flex justify-between items-center'
					)}
					style={{ transition: 'margin-right 0.2s' }}
				>
					<TitleTabSettings disabledBorder>
						{props.displayMenuBurger && (
							<MenuIcon
								className="cursor-pointer mr-10"
								onClick={() => props.setBurgerIsOpen(!props.burgerIsOpen)}
							/>
						)}
						<UsersIconBlue color={theme.palette.stracker.contrastText} />
						<h4>
							{memberTotalList.length} {t('TITLEMEMBRE')}
						</h4>

						<div style={{ marginLeft: '50px' }}>
							<ButtonStyled
								type="button"
								className={clsx(
									user.role !== 'userAdmin' && 'hidden',
									props.switchPageValue === 'AddMembers' && 'mr-3.5',
									'ml-5'
								)}
								style={{ transition: 'all 0.2s' }}
								onClick={() => addList(props.switchPageAdd)}
							>
								<CircleAddIcon
									colorPrimary={theme.palette.stracker.contrastTextRegular}
									colorSecondary={theme.palette.stracker.contrastTextRegular}
									noFill
								/>
							</ButtonStyled>
						</div>
						{props.displayMenuBurger && (
							<CancelIcon className="cursor-pointer ml-auto" onClick={props.handleCloseDialogSettings} />
						)}
					</TitleTabSettings>
				</div>
			</div>
			<div className="hidden sm:block w-full flex-col">
				<div
					className={clsx(
						props.switchPageValue === 'AddMembers' && 'mr-3.5',
						'flex justify-between items-center'
					)}
					style={{ transition: 'margin-right 0.2s' }}
				>
					<TitleTabSettings disabledBorder>
						{props.displayMenuBurger && (
							<MenuIcon
								className="cursor-pointer mr-10"
								onClick={() => props.setBurgerIsOpen(!props.burgerIsOpen)}
							/>
						)}
						<UsersIconBlue color={theme.palette.stracker.contrastText} />
						<p
							className="text-lg lg:text-xl"
							style={{ color: theme.palette.stracker.contrastText, marginLeft: '5px' }}
						>
							{memberTotalList.length} {t('TITLEMEMBRE')}
						</p>

						{props.switchPageValue !== 'AddMembers' && props.switchPageValue !== 'EditMembers' && (
							<div className="flex w-full justify-end lg:pb-10 lg:mb-10">
								<ButtonStyled
									type="button"
									className={clsx(
										user.role !== 'userAdmin' && 'hidden',
										props.switchPageValue === 'AddMembers' && 'mr-3.5',
										'ml-5'
									)}
									style={{ transition: 'all 0.2s' }}
									onClick={() => addList(props.switchPageAdd)}
								>
									{t('ADDMEMBER')}
								</ButtonStyled>
							</div>
						)}
						{props.displayMenuBurger && (
							<CancelIcon className="cursor-pointer ml-auto" onClick={props.handleCloseDialogSettings} />
						)}
					</TitleTabSettings>
				</div>
			</div>
		</>
	);
}

export default MembresHeader;
