import * as Actions from '../actions';

const initialState = {};

const features = (state = initialState, action) => {
	switch (action.type) {
		case Actions.EXPORT_EXCEL: {
			return {
				...action.payload
			};
		}
		default: {
			return state;
		}
	}
};

export default features;
