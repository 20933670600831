import axios from 'axios';
import API from '@pi/api';
import { showMessage } from 'app/store/fuse/messageSlice';

export const CREATE_NOTIFICATION_ERROR = '[SUPPLY CHAIN NOTIFICATIONS] CREATE_NOTIFICATION_ERROR';
export const CREATE_NOTIFICATION_SUCCESS = '[SUPPLY CHAIN NOTIFICATIONS] CREATE_NOTIFICATION_SUCCESS';

export const UPDATE_PREFERED_NOTIF = '[SUPPLY CHAIN NOTIFICATIONS] UPDATE PREFERED NOTIF';
export const GET_PREFERED_NOTIF = '[SUPPLY CHAIN NOTIFICATIONS] GET PREFERED NOTIF';
export const GET_SC_NOTIFICATIONS_EMAIL = '[SUPPLY CHAIN NOTIFICATIONS] GET NOTIFICATIONS EMAIL';
export const GET_SC_NOTIFICATIONS_SMS = '[SUPPLY CHAIN NOTIFICATIONS] GET NOTIFICATIONS SMS';

export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';

export const UPDATE_NOTIFICATION_ERROR = '[SUPPLY CHAIN NOTIFICATIONS] UPDATE_NOTIFICATION_ERROR';
export const UPDATE_NOTIFICATION_SUCCESS = '[SUPPLY CHAIN NOTIFICATIONS] UPDATE_NOTIFICATION_SUCCESS';

export const SET_SEARCH_TEXT = '[SUPPLY CHAIN NOTIFICATIONS] SET SEARCH TEXT';
export const GET_SC_NOTIFICATIONS = '[SUPPLY CHAIN NOTIFICATIONS] GET NOTIFICATIONS';
export const GET_SC_NOTIFICATIONS_MYNOTIFICATION = '[SUPPLY CHAIN NOTIFICATIONS] GET NOTIFICATIONS MYNOTIFICATION';

export function getNotifications() {
	const request = axios.get('/api/supply-chain-notifications/notifications');

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_NOTIFICATIONS,
				payload: response.data
			})
		);
}

export function createNotifications(data, idCustomer, idConnectedUser) {
	const request = API.post('/notification', {
		action: 'create',
		idCustomer,
		idConnectedUser,
		data
	});

	return dispatch =>
		request
			.then(response =>
				dispatch({
					type: CREATE_NOTIFICATION_SUCCESS,
					payload: response.data
				})
			)
			.then(() => dispatch(getNotificationsEmail(idCustomer)))
			.then(() => dispatch(getNotificationsSms(idCustomer)));
}

export function getPreferedNotifications(idCustomer, idUser) {
	const request = API.get('/notification/customer/preference', { params: { idCustomer, idUser } });

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_PREFERED_NOTIF,
				payload: response.data
			})
		);
}

export function updatePrefNotif(idCustomer, name, value, idUser) {
	const request = API.put(`/notification/customer/preference`, null, { params: { idCustomer, name, value, idUser } });

	return dispatch =>
		request.then(response => {
			dispatch({
				type: UPDATE_PREFERED_NOTIF,
				payload: response.data
			});
		});
}

export function getNotificationsEmail(idCustomer) {
	const request = API.post('/notification', {
		action: 'list',
		contactType: 'email',
		type: 'customer',
		idCustomer
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_NOTIFICATIONS_EMAIL,
				payload: response.data
			})
		);
}

export function getNotificationsSms(idCustomer) {
	const request = API.post('/notification', {
		action: 'list',
		contactType: 'phone',
		type: 'customer',
		idCustomer
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_NOTIFICATIONS_SMS,
				payload: response.data
			})
		);
}

export function deleteNotification(idNotif, idCustomer, idConnectedUser) {
	const request = API.post('/notification', {
		action: 'delete',
		idNotificationContact: idNotif,
		type: 'customer',
		idConnectedUser
	});

	return dispatch =>
		request
			.then(response =>
				dispatch({
					type: DELETE_NOTIFICATION_SUCCESS,
					payload: response.data
				})
			)
			.then(() => dispatch(getNotificationsEmail(idCustomer)))
			.then(() => dispatch(getNotificationsSms(idCustomer)));
}

export function setSearchText(event) {
	return {
		type: SET_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function resetSearchText() {
	return {
		type: SET_SEARCH_TEXT,
		searchText: ''
	};
}

export function getNotificationsMynotification(idNotif) {
	const request = API.post('/notification', {
		action: 'read',
		idNotificationContact: idNotif,
		type: 'customer'
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_NOTIFICATIONS_MYNOTIFICATION,
				payload: response.data
			})
		);
}

export function updateNotification(notification, idCustomer, idConnectedUser) {
	const request = API.post('/notification', {
		action: 'update',
		idCustomer,
		idConnectedUser,
		notification,
		type: 'customer'
	});
	return dispatch =>
		request
			.then(
				response =>
					Promise.all([
						dispatch({
							type: UPDATE_NOTIFICATION_SUCCESS,
							payload: response.data
						})
					]),
				dispatch(
					showMessage({
						message: 'Contacts updated', // text or html
						autoHideDuration: 3000, // ms
						anchorOrigin: {
							vertical: 'top', // top bottom
							horizontal: 'center' // left center right
						},
						variant: 'success' // success error info warning null
					})
				)
			)
			.then(() => dispatch(getNotificationsEmail(idCustomer)))
			.then(() => dispatch(getNotificationsSms(idCustomer)));
}
