import React from 'react';

function AnalyticsIcon(props) {
	return (
		<svg
			width={props.size || '18'}
			height={props.size || '18'}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={props.color || 'currentColor'}
			className={props.className}
		>
			<path
				d="M3.75 15.75L14.25 15.75C15.0784 15.75 15.75 15.0784 15.75 14.25L15.75 3.75C15.75 2.92157 15.0784 2.25 14.25 2.25L3.75 2.25C2.92157 2.25 2.25 2.92157 2.25 3.75L2.25 14.25C2.25 15.0784 2.92157 15.75 3.75 15.75Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5 12.75H12.75V6H10.5V12.75Z"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.25 12.75H7.5L7.5 9H5.25V12.75Z"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AnalyticsIcon;
