const locale = {
	TITLE: 'Customers',
	NOCLIENT: 'No customer registered',
	ADDCLIENT: 'Add a Customer',
	ADDUSER: 'Add a user',
	NEWCLIENT: 'Creation of a customer',
	PAUSECLIENT: 'Pause',
	ACTIVECLIENT: 'Activate the customer',
	DELETECLIENT: 'Delete the customer',
	EDITCLIENT: 'Edit the customer',
	PAUSEUSER: 'Pause',
	ACTIVEUSER: 'Activate user',
	DELETEUSER: 'Delete user',
	EDITUSER: 'Edit user',
	USERS: '#Users',
	SAVEUSER: 'Save',
	TRACKERS: 'Trackers',
	VOIRPLUS: 'See more',
	SEARCHHEADER: 'Search',
	CATEGORY: 'Category',
	CANCEL: 'Cancel',
	AGREE: 'Save',
	PAUSECLIENTTITLE: 'Are you sure you want to pause the customer ',
	ACTIVECLIENTTITLE: 'Are you sure you want to activate the customer ',
	DELETECLIENTTITLE: 'Are you sure you want to delete the customer ',
	PAUSECLIENTDIALOG:
		'This action will pause the customer. He will no longer have access to www.stracker360.com, nor to its notifications.',
	ACTIVECLIENTDIALOG: "Would you like to reactivate this customer's account?",
	DELETECLIENTDIALOG:
		'This action will result in the permanent deletion of the customer account. All of its users will no longer have access to www.stracker360.com, nor to their notifications.',
	PAUSEUSERTITLE: 'Are you sure you want to pause this user',
	ACTIVEUSERTITLE: 'Are you sure you want to activate this user',
	DELETEUSERTITLE: 'Are you sure you want to delete this user',
	PAUSEUSERDIALOG:
		'This action will result in the suspension of the customer account. All of its users will no longer have access to www.stracker360.com, nor to their notifications, until the account is reactivated.',
	ACTIVEUSERDIALOG:
		'This action will result in the reactivation of the user account. He will have access to www.stracker360.com, and its notifications.',
	DELETEUSERDIALOG:
		'This action will result in the permanent deletion of the user account. He will no longer have access to www.stracker360.com, nor to its notifications.',
	ENDSCROLL: 'Last reached',
	LOADING: 'Loading',
	ADDNEWFILTER: 'Filters',
	ORDERBY: 'Sort by',
	NEWUSER: 'Create a user',
	ADDUSERS: 'Add users',
	CUSTOMERNAMEAPPEAR: 'Name will appear on the dashboard',
	SOCIETE: 'Name of the society',
	RESPONSABLE: 'Company manager',
	LASTNAME: 'Name',
	FIRSTNAME: 'Surname',
	EMAIL: 'Email',
	ACTIVITY: 'Main sector',
	USER: 'User',
	SOCIETY_NAME: 'Name of the society',
	LOGO: 'Logo',
	YES: 'Yes',
	NO: 'No',
	SELECT_URL: 'Insert url of the logo',
	PREVIEW: 'Preview',
	PREVIOUS: 'Previous',
	PAYMENT_FREQUENCY: 'Payment frequency',
	DURATION_OF_COMMITMENT: 'Duration of commitment',
	TITLEPROFIL: 'My Profil',
	TITLENOTIF: 'Notifs',
	TITLEMEMBRE: 'Members',
	CONTRACTORS: 'Contractors',
	ADDRESS_BOOK: 'Phone book',
	SUBSCRIPTION: 'Subscription',
	NEXT: 'Next',
	PASSWORD: 'Password',
	PHONE: 'Phone number',
	DELETEMAIL: 'Do you want to delete this email ?',
	DELETEPHONE: 'Do you want to delete this phone number ?',
	DELETEMAILTEXT: 'Are you sure you want to delete this email ?',
	DELETEPHONETEXT: 'Are you sure you want to delete this phone number ?',
	SEARCH: 'Search...',
	NOTIFICATION: 'Notification',
	VERTICAL: 'Vertical',
	IDCONNECT: 'Login id',
	PROFILINFORMATION: 'Profil informations',
	PROFIL: 'Profile',
	PICTURE: 'Picture',
	MEMBER: 'Member',
	ACCESS: 'Access',
	USERROLE: 'User Role',
	NOTIFPARAMS: 'Configure the sending of notifications',
	UPDATE: 'Update',
	MAILNOTIF: 'Mail notification',
	PHONENOTIF: 'Phone notification',
	ADD: 'Ajouter',
	DISABLESUBSCRIPTION: 'Disable subscription',
	ACTIVATE_SUBSCRIPTION: 'Activate subscription',
	ENGAGEMENTTIME: 'Engagement duration',
	PAYEMENTFREQUENCY: 'Payement frequency',
	SUBSCRIPTIONPRICE: 'Subscription price per year',
	TRANSPORTVOLUME: 'Transport volume per year',
	BEGGININGSUBSCRIPTIONDATE: 'Subscription start date',
	SUBSCRIPTIONFIRST: 'Either a subscription with a commitment of ',
	MONTH: 'Months',
	TRANSPORT_VOLUME: 'Transport volume',
	TEXT_TRANSPORT_VOLUME: 'Relative to the engagement duration*',
	MONTHLY_SUBSCRIPTION: 'Monthly subscription price',
	VALUE: 'Value',
	SUBSCRIPTION_DATE: 'Subscription start date :',
	BUDGET: 'Budget',
	SUMMARY: 'Summary',
	ROLE: 'Role',
	IDENTITY: 'Identity',
	MEMBER_NUMBER: 'Members number',
	ACTIF_TRANSPORTS: 'Active transports number',
	TRANSPORTS_TO_BILL: 'Transports to be invoiced',
	SUBSCRIPTION_COST: 'Monthly subscription costs',
	EXPENSES_AND_BUDGET: 'Expenses / Budget',
	USES_AND_TOTAL_TRANSPORT: 'Used transport / Total transport number',
	NOT_SUBSCRIBE: 'Not subscribe',
	DELETE_MEMBER: 'Delete member',
	DELETE_MEMBER_TEXT: 'Are you sure you want to delete this member ?',
	DELETE_CONTRACTOR: 'Delete contractor',
	DELETE_CONTRACTOR_TEXT: 'Are you sure you want to delete this contractor ?',
	COUNTRY: 'Country'
};

export default locale;
