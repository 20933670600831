import React, { useState } from 'react';
import AddressBookHeader from './AddressBookHeader';
import AddressBookContent from './AddressBookContent';
import withReducer from 'app/store/withReducer';
import reducer from '../store/index';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'addressBook', fr);
i18next.addResourceBundle('en', 'addressBook', en);

function AddressBook(props) {
	const [pageRouter, setPageRouter] = useState('');

	return (
		<div className={props.className || ''}>
			<AddressBookHeader setPageRouter={setPageRouter} pageRouter={pageRouter} />
			<AddressBookContent
				setPageRouter={setPageRouter}
				pageRouter={pageRouter}
				selectedIdCustomer={props.selectedIdCustomer}
			/>
		</div>
	);
}

export default withReducer('members', reducer)(AddressBook);
