const locale = {
	TITLELANGUE: 'Language',
	TITLEMEMBRE: 'Members',
	TITLEPRESET: 'Alert presets',
	TITLENOTIF: 'Notifications',
	TITLEPROFIL: 'My profile',
	TITLELG: 'Language selection',
	DARKMODE_TXT: 'Helps reduce visual fatigue and energy consumption.',
	FLAGFR: 'French',
	FLAGEN: 'English',
	CONTRACTORS: 'Contractors',
	ADDRESS_BOOK: 'Phone Book',
	NO_MAIL: 'No e-mail address'
};

export default locale;
