import React from 'react';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import styled from 'styled-components';

function SearchStyled({ searchValue, setSearchValue, placeholder, responsive, className, placeholderFull, hideIcon }) {
	const theme = useTheme();
	return (
		<PaperStyled
			className={clsx(
				responsive && 'sm:mr-24 md:mr-0 mt-10 sm:mt-0',
				className,
				'flex items-center px-12 w-full rounded-lg sm:w-auto'
			)}
			style={{ backgroundColor: theme.palette.stracker.contrastInput }}
			elevation={0}
		>
			{hideIcon ? <> </> : <Icon color="action">search</Icon>}
			<Input
				placeholder={placeholder}
				disableUnderline
				inputProps={{
					'aria-label': 'Search'
				}}
				className={clsx(placeholderFull && 'w-full', 'py-2 pl-12 font-semibold w-full')}
				type="text"
				value={searchValue}
				onChange={setSearchValue}
			/>
		</PaperStyled>
	);
}

const PaperStyled = styled(Paper)`
	.MuiInputBase-input {
		padding: 7px 0;
		color: ${props => props.theme.palette.stracker.contrastTextDark};
	}
	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: red;
		opacity: 1; /* Firefox */
	}
`;

export default SearchStyled;
