const locale = {
	BTN_READ_ALL_NOTIFICATIONS_TEXT: 'Marquer tout comme lu',
	BTN_READ_NOTIFICATION_TEXT: 'Marquer comme lu',
	PLACEHOLDER_SEARCH: 'Rechercher',
	TITLE: 'Notification',
	DIALOG_NOTIFICATION: "Paramétrer l'envoi des notifications relatives à l'ensemble des alertes du compte client",
	UNREAD_FILTER: 'Non lus',
	READ_FILTER: 'Lus',
	SHOW_FILTERS: 'Filtrer',
	STATUS_FILTER: 'Statut',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	HIDE_FILTERS: 'Masquer les filtres',
	SEE: 'Voir',
	QUOTATION_REQUEST_CONFIRMED: 'Devis demandé',
	QUOTATION_ACCEPTED: 'Devis accepté',
	PICKUP_PLANNED: 'Pick-up prévu',
	QUOTATION_AVAILABLE: 'Devis disponible',
	SHIPMENT_HAS_LEFT_FOR_DEPARTURE: 'Le transport a quitté la zone de départ',
	SHIPMENT_HAS_ENTERED_STEP_1: 'Le transport est arrivé à la première étape 1',
	SHIPMENT_HAS_ENTERED_ARRIVAL_AREA: 'Le transport est arrivé'
};

export default locale;
