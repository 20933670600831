import API from '@pi/api';

export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR';

export const GET_ADDRESSBOOK_SUCCESS = 'GET_ADDRESSBOOK_SUCCESS';
export const GET_ADDRESSBOOK_ERROR = 'GET_ADDRESSBOOK_ERROR';

export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';

export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export function getAddressBook(idCustomer) {
	const request = API.get('/address-book', {
		params: {
			idCustomer: idCustomer
		}
	});
	return dispatch =>
		request.then(response => {
			dispatch({
				type: GET_ADDRESSBOOK_SUCCESS,
				payload: response.data
			});
		});
}

export function createContact(contact, idCustomer) {
	const request = API.post('/address-book', {
		idCustomer,
		company: contact.company,
		lastname: contact.lastname,
		firstname: contact.firstname,
		email: contact.email,
		phone: contact.phone
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: CREATE_CONTACT_SUCCESS,
					payload: response.data
				})
			]);
		});
}

export function updateContact(contact, idCustomer) {
	const request = API.put(`/address-book/${contact.id}`, {
		idCustomer,
		company: contact.company,
		lastname: contact.lastname,
		firstname: contact.firstname,
		email: contact.email,
		phone: contact.phone
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: UPDATE_CONTACT_SUCCESS,
					payload: response.data
				})
			]);
		});
}

export function deleteContact(id) {
	// eslint-disable-next-line

	const request = API.delete(`/address-book/${id}`, {});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: DELETE_CONTACT_SUCCESS,
					payload: response.data
				})
			]);
		});
}
