import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function CircleDeleteIcon(props) {
	const theme = useTheme();
	return (
		<svg
			className={props.className}
			width={props.size || '18'}
			height={props.size || '18'}
			style={{ marginRight: props.marginRight || '' }}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={props.onClick}
		>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill=""
				stroke={props.color || '#A8C1F7'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.8284 9.17139L9.17151 14.8282"
				stroke={props.color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.17163 9.17139L14.8285 14.8282"
				stroke={props.color || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default CircleDeleteIcon;
