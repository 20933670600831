const locale = {
	ADDCONTENU: 'Ajouter du contenu',
	ADDMASTER: 'Ajouter un contenant',
	ADD_BUTTON: 'Ajouter un destinataire',
	ALERTGEN: 'Alertes - personnalisées',
	ASSETS: 'Actif(s)',
	ASSOCIE: 'Associer',
	ASSOCIERCONTENANT1: 'Associer un contenant',
	ASSOCIERCONTENANT2: 'Typologie de contenant',
	ASSOCIERCONTENANT3: 'Associer votre contenant à un tracker',
	ASSOCIERCONTENANT4: 'Spécifier les alertes de votre contenant',
	ASSOCIERCONTENANT: 'Association',
	CANCEL: 'Annuler',
	CAPTEUR: 'Capteurs',
	CONNEXION: 'Connectivité',
	COMMENT_BACK: 'Fermer',
	COMMENT_BUTTON: 'Ajouter un commentaire',
	COMMENT_CONFIRM_DELETE: 'Le commentaire sera effacé. Etes-vous sûr ?',
	COMMENT_TITLE: 'Commentaires -',
	CONTAINER_INFO: 'Informations sur le colis',
	CONTENU: 'Contenu(s)',
	CONTENU_ADD_TITLE: 'Ajouter du contenu',
	CONTENU_BACK: 'Fermer',
	CONTENU_CONFIRM_DELETE: 'Du contenu sera effacé. Etes-vous sûr ?',
	CREATEAT: 'Créé',
	DANGER: 'Attention !',
	DATE_PICKER_BEGIN: 'Début',
	DATE_PICKER_END: 'Fin',
	DELETEMASTER: 'Supprimer',
	DELETEMASTERDIALOG: 'Etes-vous sûr de vouloir supprimer le contenant',
	DELETEMASTERTITLE: 'Supprimer un contenant',
	DEPRISEENCHARGE: 'Fin de prise en charge',
	DESASSOCIE: 'Dissocier',
	DIALOGEXPLICATION1: "Définissez la référence vous permettant d'identifier ce contenant",
	DIALOGEXPLICATION1BIS: 'Référence de votre contenant',
	DIALOGEXPLICATION2: 'Indiquez la catégorie du contenant qui permettra de localiser vos actifs',
	DIALOGEXPLICATION3:
		'Sélectionnez dans la liste ou dans la recherche le numéro du tracker qui sera associé à votre contenant',
	DIALOGEXPLICATION4:
		'Sélectionnez les données de votre contenant que vous souhaitez visualiser, ainsi que les alertes pour lesquelles vous serez notifié',
	DISSOCIATE: 'Dissocier',
	EDITMASTER: 'Modifier',
	EDITMASTERTITLE: 'Modifier un contenant',
	END: 'Fermer',
	ENDSCROLL: 'Dernier Atteint',
	ENDTRACKEDDIALOG: 'Finaliser',
	FIXE: 'Fixe',
	GESTION_NOTIFICATION: 'Gestion des notifications',
	HUMIDITY_HISTORY: "Historique d'humidité",
	IAM_IN_CHARGE: "je m'en charge",
	IS_IN_CHARGE: "s'en charge",
	LISTTRACKER: 'Tracker(s) disponibles',
	LOADING: 'Chargement',
	MASTER: 'Contenant',
	MOBILE: 'Mobile',
	NEW: "Création d'un contenant",
	NOTIFICATION_CONFIRM_DELETE: 'sera supprimé. Etes-vous sûr ?',
	NOTIFICATION_CONFIRM_UPDATE: 'Du contenu sera modifié. Etes-vous sûr ?',
	NOTIFICATION_EMAIL: 'Notifications par email',
	NOTIFICATION_SMS: 'Notifications par SMS',
	NOTIFICATION_SUBTITLE: "Paramétrer l'envoi des notifications relatives à cet actif",
	POSITION: 'Position',
	PRESETALERT: 'Alertes - favoris',
	PRISEENCHARGE: "Je m'en charge",
	SAVE: 'Valider',
	SAVEMASTER: 'Valider',
	SEARCHHEADER: 'Rechercher',
	SEEMORE_TITLE: 'Informations',
	TEMPERATURE_HISTORY: 'Historique des températures',
	TITLE: 'Page Example ',
	TITLE_INFO: 'Informations libres',
	TRACKEDMASTER: 'Contenant(s) connecté(s)',
	TYPOLOGIE: 'Typologie',
	TYPOLOGIEDETAIL: 'Choississez le type de contenant',
	UNLINKMASTERDIALOG: 'Etes-vous sûr de vouloir dissocier le contenant',
	UNLINKMASTERDIALOGBIS: ' du tracker',
	UNLINKMASTERTITLE: "Dissociation d'un contenant",
	UNTRACKEDMASTER: 'Contenant(s)',
	UPDATE: 'Modification des alertes du contenant',
	UP_TO_DATE: 'Informations à jour',
	NOTIFICATIONS: 'Notifications',
	REFRESH: 'Rafraichir',
	DOWNLOAD_CSV: 'Télécharger en CSV',
	SHARE: 'Partager',
	NEXT: 'Suivant',
	PREVIOUS: 'Précédent',
	ADD_EVENT: 'Ajouter un évènement',
	CUSTOM_EVENT: 'Information',
	CUSTOM_EVENT_SUBTITLE: "Décrivez l'événement à rajouter au fil d'actualité",
	DESCRIBE_EVENT: 'Détails',
	SEND_NOTIFICATION: 'Envoyer une notification',
	DONT_SEND_NOTIFICATION: 'Ne pas envoyer une notification',
	ADD: 'Ajouter',
	SHARED_FILES: 'Documents partagés',
	LIST: 'Liste',
	TEXT_SHARED_FILES:
		'Voici la liste des documents liés à votre transport. Pour télécharger un document, il vous suffit de cliquer dessus',
	TEXT_ADD_FILES:
		"Sélectionnez une catégorie relative au document que vous chargez. Pour plus de fluidité, la taille maximale d'un fichier est de 2Mo",
	ADD_FILE: 'Ajouter un document',
	CLOSE: 'Fermer',
	NEW_FILE: 'Nouveau document',
	UPLOAD_FROM_COMPUTER: 'Charger depuis votre ordinateur',
	SELECT_CATEGORY: 'Sélectionner Catégorie',
	QUOTE: 'Devis',
	PACKING_LIST: 'Liste de colisage',
	INVOICE: 'Facture',
	DGR: 'DGR',
	TRANSPORT: 'Transport',
	CUSTOM: 'Douane',
	PICK_UP: 'Collecte',
	UPDATE_CATEGORY: 'Mis a Jour',
	DELIVERY: 'Livraison',
	REPORT: 'Rapport',
	BILING: 'Facturation',
	OTHER: 'Autre',
	AGENT: 'Agent',
	TEXT_UPLOAD_FILE: 'Ce fichier a été envoyé : ',
	UPLOAD: 'Chargement',
	OVERSIZED_FILE: 'La taille maximum du fichier est de 5Mo',
	EXISTING_FILE: 'Ce fichier existe déjà',
	NO_CATEGORY: "La catégorie n'a pas été définie",
	NO_FILE: "Aucun fichier n'a été choisie",
	DELETE: 'Supprimer',
	DELETE_FILE: 'Supprimer le fichier',
	DELETE_FILE_TEXT: 'Êtes vous sûr de vouloir supprimer ce fichier ?',
	PICKUP_PLANNED: 'Récupération prévue',
	PICKUP_CONFIRMED: 'Récupération confirmée',
	BOOKING_CONFIRMED: 'Réservation confirmée',
	CUSTOM_CLEARANCE_IN_PROGRESS: 'Dédouanement en cours',
	CUSTOM_CLEARANCE_IN_CONFIRMED: 'Dédouanement confirmé',
	CARGO_DELIVERED_TO_AIRLINE: 'Cargaison livrée à la compagnie aérienne',
	CARGO_ACCEPTANCE_IN_PROGRESS: 'Réception du fret en cours',
	CARGO_ACCEPTANCE_CONFIRMED_BY_AIRLINE: 'Acceptation du fret confirmée par la compagnie aérienne',
	FLIGHT_DEPARTURE_CONFIRMED: 'Départ du vol confirmé',
	FLIGHT_ARRIVAL_CONFIRMED: 'Arrivé du vol confirmée',
	CARGO_HAS_BEEN_DELIVERED: 'La cargaison a été livrée',
	IN_CHARGE: 'En charge',
	INDOOR: 'Position',
	EDIT: 'Modification'
};

export default locale;
