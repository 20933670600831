import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import API from '@pi/api'; // call api url
import _ from '@lodash';
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';

class ApiJwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		API.interceptors.response.use(
			response => {
				return new Promise((resolve, reject) => {
					resolve(response);
				});
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access token');
						this.setSession(null);
						window.localStorage.clear();
						history.push({
							pathname: '/login'
						});
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();
		const refresh_token = this.getRefreshToken(); // PLS - 05/01/2021 - Reflesh Token

		if (!access_token || _.isEmpty(access_token)) {
			this.emit('onNoAccessToken');
			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			const allToken = {
				token: access_token,
				refresh_token
			};
			this.setSession(allToken);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access token expired');
		}
	};

	/** **  PLS - 05/01/2021 -  Login_check utilisateur API *** */
	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			const request = API.post('/login_check', {
				username: email,
				password
			});

			request
				.then(response => {
					if (!_.isEmpty(response.data.token) && !_.isEmpty(response.data.refresh_token)) {
						const access_token = {
							token: response.data.token,
							refresh_token: response.data.refresh_token
						};

						this.setSession(access_token);
						const usernameLog = response.data.data.idUser ? response.data.data.idUser : email;
						const user = this.getUserInfo(usernameLog);

						user.then(userInfo => {
							const UUID = FuseUtils.generateGUID();

							if (!_.isEmpty(userInfo.data)) {
								const infoLog = {
									id: response.data.data.idUser,
									email: userInfo.data.email
								};
								this.setSessionUser(infoLog);

								// eslint-disable-next-line no-shadow
								const user = {
									uuid: UUID,
									id: response.data.data.idUser,
									role: response.data.data.role,
									from: 'apijwt',
									data: {
										displayName: `${userInfo.data.firstName} ${userInfo.data.lastName}`,
										email: userInfo.data.email,
										firstname: userInfo.data.firstName,
										lastname: userInfo.data.lastName,
										username: userInfo.data.username,
										idUser: userInfo.data.idUser,
										idRole: userInfo.data.idRole,
										idCustomer: userInfo.data.idCustomer,
										phone: userInfo.data.phone,
										photoURL: userInfo.data.photo,
										language: userInfo.data.language,
										typeClient: userInfo.data.typeClient,
										password: userInfo.data.password,
										passwordForgot: userInfo.data.passwordForgot,
										lastConnection: userInfo.data.lastConnection,
										customerName: userInfo.data.customerName,
										vertical: userInfo.data.vertical,
										logo: userInfo.data.logo,
										subscribed: userInfo.data.subscribed
									}
								};

								resolve(user);
							} else {
								reject(response.data.error);
							}
						});
					} else {
						reject(response.data.error);
					}
				})
				.catch(error => {
					history.push({
						pathname: '/login'
					});
					this.logout();
					reject(error.response.data);
				});
		});
	};

	/** **  PLS - 05/01/2021 - signIn user With Token in Api * */
	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			// const refresh_token = this.getRefreshToken();
			const access_token = this.getAccessToken();
			const decoded = jwtDecode(access_token);

			API.post('/token/refresh', {
				refresh_token: this.getRefreshToken()
			})
				.then(response => {
					if (!_.isEmpty(response.data.token) && !_.isEmpty(response.data.refresh_token)) {
						const signin_token = {
							token: response.data.token,
							refresh_token: response.data.refresh_token
						};
						this.setSession(signin_token);

						const usernameLog = response.data.data.idUser ? response.data.data.idUser : decoded.username;
						const user = this.getUserInfo(usernameLog);

						user.then(userInfo => {
							const UUID = FuseUtils.generateGUID();

							if (!_.isEmpty(userInfo.data)) {
								// eslint-disable-next-line no-shadow
								const user = {
									uuid: UUID,
									id: response.data.data.idUser,
									role: response.data.data.role,
									from: 'apijwt',
									data: {
										displayName: `${userInfo.data.firstName} ${userInfo.data.lastName}`,
										email: userInfo.data.email,
										firstname: userInfo.data.firstName,
										lastname: userInfo.data.lastName,
										username: userInfo.data.username,
										idUser: userInfo.data.idUser,
										idRole: userInfo.data.idRole,
										idCustomer: userInfo.data.idCustomer,
										phone: userInfo.data.phone,
										photoURL: userInfo.data.photo,
										language: userInfo.data.language,
										typeClient: userInfo.data.typeClient,
										password: userInfo.data.password,
										passwordForgot: userInfo.data.passwordForgot,
										lastConnection: userInfo.data.lastConnection,
										customerName: userInfo.data.customerName,
										vertical: userInfo.data.vertical,
										logo: userInfo.data.logo,
										subscribed: userInfo.data.subscribed
									}
								};

								resolve(user);
							} else {
								showMessage({
									message: 'Failed to login',
									autoHideDuration: 2000,
									variant: 'error',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'right'
									}
								});
								this.logout();
								history.push({
									pathname: '/login'
								});
								// Promise.reject(new Error('Failed to login with token.'));
							}
						});
					} else {
						showMessage({
							message: 'Failed to login',
							autoHideDuration: 2000,
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right'
							}
						});
						this.logout();
						history.push({
							pathname: '/login'
						});
						// Promise.reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					error = error.response.status === 401 ? error.response.status : 401;
					if (error === 401) {
						showMessage({
							message: 'access token expired',
							autoHideDuration: 2000,
							variant: 'error',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right'
							}
						});
						// reject(error.response.data);
						reject(error);
					}
					this.logout();
					history.push({
						pathname: '/login'
					});
					// Promise.reject(new Error('Failed to login with token.'));
				});
		});
	};

	// PLS - 14/01/2021 - create && signIn new user in Api
	createUser = model => {
		return new Promise((resolve, reject) => {
			const { firstname, name, phone, email, password, passwordConfirm, language } = model;

			API.post('noauth', {
				action: 'newRegister', // action: 'register', (OLD Version)
				email,
				firstName: firstname,
				lastName: name,
				phone,
				password,
				confirmPassword: passwordConfirm,
				language
			})
				.then(response => {
					if (response.data.status === 'ok') {
						resolve(response.data);
						// history.push('/usersuccessful');
					} else {
						reject(response.data);
					}
				})
				.catch(error => {
					Promise.reject(new Error('Failed to create User.'));
				});
		});
	};

	//  - 16/01/2021 - send email to Api (Forgot password)
	forgotUser = model => {
		return new Promise((resolve, reject) => {
			const { email } = model;

			API.post('noauth', {
				action: 'mailpwd',
				email
			})
				.then(response => {
					if (response.data.status === 'ok') {
						history.push('/emailsent');
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch(error => {
					Promise.reject(error.data);
				});
		});
	};

	//  - 17/01/2021 - reset pwd user
	resetpwdUser = model => {
		return new Promise((resolve, reject) => {
			const { email, password } = model;

			API.post('noauth', {
				action: 'updpwd',
				email,
				password
			}).then(response => {
				if (response.data.status === 'ok') {
					history.push('/passwordmodified');
					resolve(response.data);
				} else {
					reject(response.data);
				}
			});
		});
	};

	/** **  PLS - 05/01/2021 -  Login_check get user info API SF *** */
	getUserInfo = idUser => {
		return API.post('/user', {
			idUser,
			action: 'read'
		});
	};

	/** **  PLS - Set AcessToken && RefreshToken *** */
	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token.token);
			localStorage.setItem('jwt_refresh_token', access_token.refresh_token);
			API.defaults.headers.common.Authorization = `Bearer ${access_token.token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			localStorage.removeItem('jwt_refresh_token');
			localStorage.clear();
			delete API.defaults.headers.common.Authorization;
		}
	};

	/** **  PLS - 20/02/2021 - Set accessUser in Session  *** */
	setSessionUser = accessUser => {
		if (accessUser) {
			localStorage.setItem('userIdLog', accessUser.id);
			localStorage.setItem('userIdEmail', accessUser.email);
		} else {
			localStorage.removeItem('userIdLog');
			localStorage.removeItem('userIdEmail');
		}
	};

	/** **  PLS - 20/02/2021 - Logout in APP && API  *** */
	logout = () => {
		return new Promise(() => {
			const request = API.post('/auth', {
				action: 'logout',
				idUser: localStorage.getItem('userIdLog'),
				email: localStorage.getItem('userIdEmail')
			});
			request.then(response => {
				this.setSession(null);
				// localStorage.removeItem('setLanguage');
				window.localStorage.clear();
				if (response.data.status === 'ok') {
					showMessage({
						message: 'You Logout',
						autoHideDuration: 2000,
						variant: 'info ',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					});
					history.push({
						pathname: '/login'
					});
				}
			});
		});
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}

		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;

		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};

	//  PLS - 05/01/2021 - get RefreshToken from session
	getRefreshToken = () => {
		return window.localStorage.getItem('jwt_refresh_token');
	};

	/** **  PLS - 18/02/2021 -  Switch redirection URL on user Login *** */
	redirectUserUrl = type => {
		switch (type) {
			case 'SC':
				return '/supplychain/dashboard';
			case 'TR':
				return '/dashboard';
			default:
				return '/main/dashboards';
		}
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};
}

const instance = new ApiJwtService();

export default instance;
