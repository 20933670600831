import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import forgotpassword from './forgotpasswordSlice';
import resetpassword from './resetpasswordSlice';
import features from './reducers/features.reducer';
import user from './userSlice';

const authReducers = combineReducers({
	user,
	login,
	register,
	forgotpassword,
	resetpassword,
	features
});

export default authReducers;
