const locale = {
	BTN_READ_ALL_NOTIFICATIONS_TEXT: 'Mark all as read',
	BTN_READ_NOTIFICATION_TEXT: 'Mark as read',
	PLACEHOLDER_SEARCH: 'Search for',
	TITLE: 'Notification',
	DIALOG_NOTIFICATION: 'Set-up the notifications applicable to all alerts related to your customer account.',
	READ_FILTER: 'Read',
	HIDE_FILTERS: 'Hide filters',
	UNREAD_FILTER: 'Unread',
	SHOW_FILTERS: 'Show filters',
	STATUS_FILTER: 'Status',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	SEE: 'See',
	QUOTATION_REQUEST_CONFIRMED: 'Quotation request confirmed',
	QUOTATION_ACCEPTED: 'Quotation accepted',
	PICKUP_PLANNED: 'Pick-up planned',
	QUOTATION_AVAILABLE: 'Quotation available',
	SHIPMENT_HAS_LEFT_FOR_DEPARTURE: 'Shipment has left for departure',
	SHIPMENT_HAS_ENTERED_STEP_1: 'Shipment has entered step 1',
	SHIPMENT_HAS_ENTERED_ARRIVAL_AREA: 'Shipment has entered arrival area'
};

export default locale;
