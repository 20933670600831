import _ from '@lodash';
import * as Actions from '../addressBook/store/actions';

const initialState = [];

const addressbook = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_ADDRESSBOOK_SUCCESS: {
			return {
				state: _.keyBy(action.payload, 'id')
			};
		}
		case Actions.UPDATE_CONTACT_SUCCESS: {
			return {
				...state
			};
		}
		case Actions.DELETE_CONTACT_SUCCESS: {
			return {
				...initialState
			};
		}
		case Actions.DELETE_CONTACT_ERROR: {
			return {
				success: false,
				error: action.payload
			};
		}
		case Actions.CREATE_CONTACT_SUCCESS: {
			return {
				...state
			};
		}
		case Actions.CREATE_CONTACT_ERROR: {
			return {
				success: false
			};
		}
		default:
			return state;
	}
};

export default addressbook;
