const locale = {
	ANALYTICS: 'Analytics',
	START: 'Début',
	END: 'Fin',
	TOP_DESTINATION: 'Top de vos destinations',
	TRANSPORT_TYPE: 'Répartition de vos transports',
	IMPORT_EXPORT: 'Répartition de vos imports/exports',
	AVERAGE_TRANSPORT_BUDGET: "Budget moyen d'un transport",
	AVERAGE_TRANSPORT_TIME: "Durée moyenne d'un transport",
	TRANSPORT_WEIGHT: "Poids et prix moyen d'un colis",
	GROSS_WEIGHT_PRICE: '€/Kg moyen brut',
	GROSS_WEIGHT: 'Poids brut',
	CHARGEABLE_WEIGHT_PRICE: '€/Kg moyen chargeable',
	CHARGEABLE_WEIGHT: 'Poids chargeable',
	AVERAGE_PACKAGE: 'Nombre de colis moyen',
	MISSING_DATA: 'Donnée manquante',
	DAY: 'jour',
	DAYS: 'jours',
	HOUR: 'heure',
	HOURS: 'heures'
};

export default locale;
