const locale = {
	TITLELANGUE: 'Langue',
	TITLEMEMBRE: 'Membres',
	TITLEPRESET: 'Mes alertes favorites',
	TITLENOTIF: 'Notifications',
	TITLEPROFIL: 'Mon profil',
	TITLELG: 'Sélection de la langue',
	DARKMODE_TXT: "Permet de réduire la fatigue visuelle et la consommation d'énergie.",
	FLAGFR: 'Français',
	FLAGEN: 'Anglais',
	CONTRACTORS: "Donneur d'ordre",
	ADDRESS_BOOK: "Annuaire"
};

export default locale;
