const locale = {
	ANALYTICS: 'Analytics',
	START: 'Start',
	END: 'End',
	TOP_DESTINATION: 'Top of your transport destination',
	TRANSPORT_TYPE: 'Distribution of your transport',
	IMPORT_EXPORT: 'Distribution of your imports/exports',
	AVERAGE_TRANSPORT_BUDGET: 'Average budget for a transport',
	AVERAGE_TRANSPORT_TIME: 'Average transport time',
	TRANSPORT_WEIGHT: 'Weight and price of your packages',
	GROSS_WEIGHT_PRICE: 'Average gross price per €/Kg',
	GROSS_PRICE: 'Average raw weight',
	CHARGEABLE_WEIGHT_PRICE: 'Average Chargeable Weight',
	CHARGEABLE_WEIGHT: 'Average chargeable per €/Kg',
	AVERAGE_PACKAGE: 'Average package number',
	MISSING_DATA: 'This data is missing',
	DAY: 'day',
	DAYS: 'days',
	HOUR: 'hour',
	HOURS: 'hours'
};

export default locale;
