import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import * as Actions from './store/actions';
import Input from '@material-ui/core/Input';
import PhoneInput from 'react-phone-input-2';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	MboutonBoxV: {
		borderRadius: '8px',
		minWidth: '100px',
		height: '34px'
	},
	hoverColor: {
		backgroundColor: '#fff'
	},
	hoverValidButton: {
		backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
	},
	hoverPhoneButton: {
		backgroundColor: theme.palette.stracker.contrastInput
	},
	'@global': {
		'.mbottomButtom': {
			width: '220px',
			textAlign: 'right',
			marginLeft: 'auto'
		},
		'.hoverColor:hover': {
			backgroundColor: theme.palette.stracker.contrastText, // '#074EE8',
			color: '#fff !important'
		},
		'hoverValidButton:hover': {
			backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
		},
		'.mbottomButtom .hoverValidButton:hover': {
			backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
		},
		'.mbottomButtom .bcancel': {
			backgroundColor: 'transparent',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			border: `1px solid ${theme.palette.secondary.colorBorderSelect}` // #A8C1F7'
		},
		'.hoverPhoneButton:hover': {
			backgroundColor: theme.palette.stracker.contrastInput
		}
	}
}));

const AddressBookForm = ({
	setPageRouter,
	pageRouter,
	selectedContact,
	filteredData,
	setFilteredData,
	selectedIdCustomer
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('addressBook');

	const user = useSelector(({ auth }) => auth.user);
	const addressBookList = useSelector(({ members }) => members.addressbook.state);

	const { idCustomer } = user.data;

	const [formList, setFormList] = useState({ company: '', email: '', phone: '', lastname: '', firstname: '' });

	// Permet de récupérer le curent contractor
	useEffect(() => {
		if (user.data.idCustomer) {
			dispatch(Actions.getAddressBook(user.data.idCustomer === 0 ? selectedIdCustomer : user.data.idCustomer));
		}
		// eslint-disable-next-line
	}, [user, selectedContact && selectedContact.idContact]);

	useEffect(() => {
		function getFilteredArray() {
			const arr = Object.keys(addressBookList).map(id => addressBookList[id]);
			return arr;
		}

		if (addressBookList) {
			setFilteredData(getFilteredArray());
		}
		// eslint-disable-next-line
	}, [addressBookList]);

	// va définir les valeurs de défault de l'input si on est dans le cas d'une modification
	useEffect(() => {
		console.log(formList);
		let list = {};
		list.company = selectedContact ? selectedContact.company : '';
		list.email = selectedContact ? selectedContact.email : '';
		list.phone = selectedContact ? selectedContact.phone : '';
		list.firstname = selectedContact ? selectedContact.firstname : '';
		list.lastname = selectedContact ? selectedContact.lastname : '';
		list.id = selectedContact ? selectedContact.id : '';
		setFormList(list);
		// eslint-disable-next-line
	}, []);

	// Va changer la valeur du form à chaque changement
	const handleInputChange = e => {
		const { name, value } = e.target;
		const list = { ...formList };
		list[name] = value;
		setFormList(list);
	};

	// fonction spécifique pour l'input phone qui est diff des autres
	const handleChangePhone = e => {
		const list = { ...formList };
		list.phone = e;
		setFormList(list);
	};

	// Va exécuter l'action au moment de la soumission du forme
	async function handleSubmit(event) {
		event.preventDefault();
		if (pageRouter === 'create') {
			dispatch(Actions.createContact(formList, idCustomer === 0 ? selectedIdCustomer : idCustomer)).then(() => {
				dispatch(
					Actions.getAddressBook(user.data.idCustomer === 0 ? selectedIdCustomer : user.data.idCustomer)
				);
			});
		} else {
			let filteredDataCpy = filteredData;
			filteredData.forEach((data, i) => {
				if (selectedContact.idContact === filteredDataCpy[i].idContact) {
					filteredDataCpy[i].company = formList.company;
					filteredDataCpy[i].email = formList.email;
					filteredDataCpy[i].phone = formList.phone;
					filteredDataCpy[i].firstname = formList.firstname;
					filteredDataCpy[i].lastname = formList.lastname;
				}
			});

			await dispatch(Actions.updateContact(formList, idCustomer === 0 ? selectedIdCustomer : idCustomer));
			await dispatch(Actions.getAddressBook(idCustomer === 0 ? selectedIdCustomer : idCustomer));
			setFilteredData(filteredDataCpy);
		}
		setPageRouter('');
	}

	return (
		<div className="flex flex-col">
			<div>
				<div
					className={clsx('px-12 py-5 w-full rounded-lg mt-16')}
					style={{ backgroundColor: theme.palette.stracker.contrastInput }}
				>
					<Input
						style={{ width: '100%', color: theme.palette.stracker.contrastTextDark, fontWeight: 600 }}
						placeholder={t('COMPANY')}
						disableUnderline
						name="company"
						onChange={e => handleInputChange(e)}
						defaultValue={selectedContact && selectedContact.company ? selectedContact.company : ''}
					/>
				</div>
				<div className="flex">
					<div
						className={clsx('px-12 py-5 w-full rounded-lg mt-16 mr-3')}
						style={{ backgroundColor: theme.palette.stracker.contrastInput }}
					>
						<Input
							style={{
								width: '100%',
								color: theme.palette.stracker.contrastTextDark,
								fontWeight: 600
							}}
							placeholder={t('FIRST_NAME')}
							disableUnderline
							name="firstname"
							onChange={e => handleInputChange(e)}
							defaultValue={selectedContact && selectedContact.firstname ? selectedContact.firstname : ''}
						/>
					</div>
					<div
						className={clsx('px-12 py-5 w-full rounded-lg mt-16 ml-3')}
						style={{ backgroundColor: theme.palette.stracker.contrastInput }}
					>
						<Input
							style={{
								width: '100%',
								color: theme.palette.stracker.contrastTextDark,
								fontWeight: 600
							}}
							placeholder={t('LAST_NAME')}
							disableUnderline
							name="lastname"
							onChange={e => handleInputChange(e)}
							defaultValue={selectedContact && selectedContact.lastname ? selectedContact.lastname : ''}
						/>
					</div>
				</div>
				<div
					className={clsx('px-12 py-5 w-full rounded-lg mt-16')}
					style={{ backgroundColor: theme.palette.stracker.contrastInput }}
				>
					<Input
						style={{ width: '100%', color: theme.palette.stracker.contrastTextDark, fontWeight: 600 }}
						placeholder={t('EMAIL')}
						disableUnderline
						name="email"
						onChange={e => handleInputChange(e)}
						defaultValue={selectedContact && selectedContact.email ? selectedContact.email : ''}
					/>
				</div>

				<PhoneInput
					country={'fr'}
					className={clsx('flex items-center  rounded-lg sm:w-auto mt-16')}
					name="phone"
					containerStyle={{ width: '100%' }}
					inputStyle={{
						backgroundColor: theme.palette.stracker.contrastInput,
						width: '100%',
						border: 'none',
						color: theme.palette.stracker.contrastTextDark,
						fontWeight: 600
					}}
					buttonClass={clsx(classes.hoverPhoneButton)}
					buttonStyle={{
						backgroundColor: theme.palette.stracker.contrastInput,
						border: 'none',
						borderRight: '1px solid lightgrey'
					}}
					placeholder={t('PHONE')}
					onChange={event => handleChangePhone(event)}
					value={selectedContact && selectedContact.phone ? selectedContact.phone : ''}
				/>
			</div>
			<div className="bottom-14 mbottomButtom" style={{ position: 'absolute', right: '5%', bottom: '15px' }}>
				<Button
					variant="contained"
					color="primary"
					className={clsx(
						classes.MboutonBoxV,
						'bcancel w-content mx-auto normal-case shadow-none text-white float-left'
					)}
					aria-label="CANCEL"
					type="cancel"
					onClick={() => setPageRouter('')}
				>
					{t('CANCEL')}
				</Button>
				<Tooltip
					title={
						formList.identity === '' ||
						formList.phone.length < 11 ||
						formList.firstname === '' ||
						formList.lastname === ''
							? t('MANDATORY_INPUT')
							: ''
					}
					aria-label="refresh page"
				>
					<div>
						<Button
							variant="contained"
							color="primary"
							disabled={
								formList.identity === '' ||
								formList.phone.length < 11 ||
								formList.firstname === '' ||
								formList.lastname === ''
							}
							className={clsx(
								classes.MboutonBoxV,
								'w-content mx-auto normal-case shadow-none text-white'
							)}
							aria-label={pageRouter === 'create' ? 'CREATE' : 'UPDATE'}
							style={{
								background:
									formList.identity === '' ||
									formList.phone.length < 11 ||
									formList.firstname === '' ||
									formList.lastname === ''
										? '#EEEEEE'
										: theme.palette.stracker.contrastText
							}}
							type={pageRouter === 'create' ? 'create' : 'update'}
							onClick={handleSubmit}
						>
							{pageRouter === 'create' ? t('CREATE') : t('UPDATE')}
						</Button>
					</div>
				</Tooltip>
			</div>
		</div>
	);
};

export default AddressBookForm;
