export const EXPORT_EXCEL = 'EXPORT_EXCEL';

export function exportExcelLoader(isLoading) {
	return dispatch => {
		dispatch({
			type: EXPORT_EXCEL,
			payload: { loadingExcel: isLoading /*PUT VAR FROM PARAM HERE*/ }
		});
	};
}
