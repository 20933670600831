import React from "react";

import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'analytics', fr);
i18next.addResourceBundle('en', 'analytics', en);

const AnalyticsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/analytics',
			component: React.lazy(() => import('./Analytics'))
		}
	]
};

export default AnalyticsConfig;