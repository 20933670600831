import React, { useEffect, useState } from 'react';
import FusePageSimple from '@stracker/core/FusePageSimple';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import NotificationsHeader from './NotificationsHeader';
import NotificationsContent from './NotificationsContent';
import withReducer from '../../../store/withReducer';
import reducer from './store/reducers';
import * as Actions from './store/actions';
import './notification.css';
import FilterBarStyled from '../../../../@stracker/components/StyledComponents/FilterBarStyled';

function Notifications() {
	const { t } = useTranslation('notifications');
	const notificationsDatas = useSelector(state => state.notificationsData.notificationsData);

	const dialogNotificationStatus = useSelector(
		({ notificationsData }) => notificationsData.notificationsData.dialogIsOpen
	);
	const userLog = useSelector(({ auth }) => auth.user);
	const dispatch = useDispatch();
	// eslint-disable-next-line
	const [selectValueState, setSelectValueState] = useState('');
	const [displaySelectsBottom, setDisplaySelectsBottom] = useState(false);
	const [searchTextValue, setSearchTextValue] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const [selectTop, setSelectTop] = useState([
		{
			selectName: t('RANGE_BY'),
			value: '',
			placeholder: null,
			placeholderIcon: null,
			options: [
				{
					value: 'ASC',
					checked: false,
					icon: null,
					label: t('RANGE_BY_ASC')
				},
				{
					value: 'DSC',
					checked: false,
					icon: null,
					label: t('RANGE_BY_DSC')
				}
			]
		}
	]);

	useEffect(() => {
		setIsLoading(true);
		if (dialogNotificationStatus) {
			dispatch(Actions.getNotifications(userLog.id, userLog.data.idCustomer, 1));
		}
		setIsLoading(false);
		// eslint-disable-next-line
	}, [dialogNotificationStatus]);

	const handleCloseDialogNotifications = () => {
		dispatch(Actions.updateStatusDialog(false));
	};

	if (!notificationsDatas.dialogIsOpen) return null;

	if (isLoading) {
		return (
			<div className="flex h-full justify-center items-center">
				<CircularProgress size={50} />
			</div>
		);
	}

	return (
		<DialogStyled
			onClose={handleCloseDialogNotifications}
			aria-labelledby="dialog-notification"
			open={dialogNotificationStatus}
			fullWidth
			maxWidth="lg"
		>
			<FusePageSimple
				header={
					<NotificationsHeader
						unreadNotificationsCount={notificationsDatas.totalUnread}
						searchTextValue={searchTextValue}
						setSearchTextValue={setSearchTextValue}
						hasNotificationUnread={notificationsDatas.totalUnread !== 0}
						userLog={userLog}
					/>
				}
				contentToolbar={
					<FilterBarStyled
						selectTop={selectTop}
						setSelectTop={setSelectTop}
						displaySelectsBottom={displaySelectsBottom}
						setDisplaySelectsBottom={setDisplaySelectsBottom}
						t={t}
					/>
				}
				content={
					notificationsDatas.data && notificationsDatas.status !== 'INIT' ? (
						<NotificationsContent
							sortBy={selectTop[0].value?.value || 'ASC'}
							selectValueState={selectValueState}
							notificationsData={notificationsDatas.data}
							displayFilterSelect={displaySelectsBottom}
							searchTextValue={searchTextValue}
							spaceHeaderAndToolbarContent={165}
							handleCloseDialogNotifications={handleCloseDialogNotifications}
						/>
					) : (
						<div className="flex h-full justify-center items-center">
							<CircularProgress size={50} />
						</div>
					)
				}
				infiniteScroll
			/>
		</DialogStyled>
	);
}

const DialogStyled = styled(Dialog)`
	/* .MuiDialog-paper {
		overflow-y: hidden;
	} */
	@media (max-width: 400px) {
		.MuiDialog-paperFullWidth {
			width: 100%;
		}
	}
`;

export default withReducer('notificationsData', reducer)(Notifications);
