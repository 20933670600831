const locale = {
	TITLE: 'Page Example ',
	TITLEMEMBRE: 'Membres',
	NO_MESSAGES: 'Aucun membre à afficher',
	MEMBERS: 'Membres',
	ACCESS: 'Accès',
	ADDMEMBER: 'Ajouter un membre',
	MODIFMEMBER: 'Modifier un membre',
	FIRSTNAME: 'Prénom',
	NAME: 'Nom',
	TEL: 'Téléphone',
	EMAIL: 'Email',
	UPDATE: 'Valider',
	CANCEL: 'Annuler',
	MEMBMODIF: 'Modifier le membre',
	MEMBSUPPR: 'Supprimer le membre',
	CONFIRM_DELETE_USER: 'Etes-vous sûr de vouloir supprimer le membre ?',
	BOUTON_VALIDER: 'Valider',
	BOUTON_CANCEL: 'Annuler',
	ADDUSER: 'Ajouter un membre',
	ADDUSERS: 'Ajouter des membres',
	EMAILNOVALID: 'Adresse email non valide',
	CREATE: 'Valider',
	UPDACNTSUC: 'Membre modifié avec succès',
	UPDACNTERR: 'Attention. Une erreur est survenue lors de la modification',
	CREATESUCC: 'Membre(s) créé(s) avec succès',
	CREATEERR: 'Ce membre existe déjà',
	DELETESUCC: 'Utilisateur supprimé avec succès',
	DELETEERR: 'Attention. Une erreur est survenue lors de la suppression',
	FISRTPMSG: 'Attention. Une erreur est survenue lors de la création. L(es) adresse(s) suivante(s) existe(nt) déjà',
	REQUIRED: 'Obligatoire',
	PLACEHOLDER_SEARCH: 'Rechercher',
	ROLE: 'Fonction'
};

export default locale;
