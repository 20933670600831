import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { OutlinedInput, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';
import TransportIcon from '@stracker/customicons/TransportIcon';
import CircleAddIconBlue from '@stracker/customicons/CircleAddIconBlue';
import PlaneIcon from '@stracker/customicons/PlaneIcon';
import TruckIcon from '@stracker/customicons/TruckIcon';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	font1: {
		color: theme.palette.stracker.contrastTextDark,
		fontWeight: 600,
		fontSize: '12px'
	},
	select: {
		backgroundColor: 'none',
		'&:after': {
			borderBottomColor: theme.palette.stracker.contrastTextDark
		},
		'& .MuiSvgIcon-root': {
			color: theme.palette.stracker.contrastTextDark
		}
	}
}));

function TransportTypeFilterDropDown({ filtersList, setFiltersList }) {
	const theme = useTheme();
	const { t } = useTranslation('history');
	const classes = useStyles();
	const matchesSm = useMediaQuery('(min-width:600px)');

	const filterTypeTransport = [
		{
			name: 'car',
			display: true,
			label: (
				<div className="flex items-center">
					<div style={{ marginLeft: '5px', marginRight: '5px' }}>
						<TruckIcon />
					</div>
					<span
						style={{ fontSize: '14px', fontWeight: '600', color: theme.palette.stracker.contrastTextDark }}
					>
						{t('CAR')}
					</span>
				</div>
			)
		},
		{
			name: 'plane',
			display: true,
			label: (
				<div className="flex items-center">
					<div style={{ marginLeft: '5px', marginRight: '5px' }}>
						<PlaneIcon />
					</div>
					<span
						style={{ fontSize: '14px', fontWeight: '600', color: theme.palette.stracker.contrastTextDark }}
					>
						{t('PLANE')}
					</span>
				</div>
			)
		}
	];

	const handleClick = async name => {
		let filtersListCpy = filtersList;
		// eslint-disable-next-line
		filtersList.map((filter, i) => {
			if (filter.type === 'transportType') {
				filtersListCpy.splice(i, 1);
			}
		});
		filtersListCpy.push({ type: 'transportType', name: name });
		await setFiltersList([...filtersListCpy]);
	};

	return (
		<Select
			className={classes.select}
			displayEmpty
			value={''}
			input={<OutlinedInputStyled />}
			style={{
				borderRadius: '8px'
			}}
			renderValue={selected => {
				return (
					<div style={{ display: 'flex' }}>
						<TransportIcon color={theme.palette.stracker.contrastTextDark} />
						{matchesSm && (
							<p className={classes.font1} style={{ marginLeft: '5px' }}>
								{t('TRANSPORT_TYPE')}
							</p>
						)}
					</div>
				);
			}}
			inputProps={{ 'aria-label': 'Without label' }}
			components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
		>
			{filterTypeTransport.map((data, index) => (
				<MenuItem key={index} value={data} onClick={() => handleClick(data.name)}>
					<CircleAddIconBlue />
					{data.label}
				</MenuItem>
			))}
		</Select>
	);
}

export default TransportTypeFilterDropDown;

const OutlinedInputStyled = styled(OutlinedInput)`
	.MuiOutlinedInput-input {
		padding-top: 4px;
		padding-bottom: 4px;
		height: 18px;
	}
	.MuiMenu-paper {
		top: 10px !important;
	}
`;
