import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import CircleAddIcon from '@stracker/customicons/CircleAddIcon';
import CircleMinusIcon from '@stracker/customicons/CircleMinusIcon';
import CotationFilterDropDown from 'app/main/apps/transport/cotation/CotationFilterDropDown';
import TransportTypeFilterDropDown from 'app/main/apps/history/TransportTypeFilterDropDown';
import CountrySelector from 'app/main/apps/history/CountrySelector';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import ArrowUpRight from '@stracker/customicons/ArrowUpRight';
import ArrowDownRight from '@stracker/customicons/ArrowDownRight';
import ClientFilter from 'app/main/apps/history/ClientFilter';
import TruckIcon from '@stracker/customicons/TruckIcon';
import PlaneIcon from '@stracker/customicons/PlaneIcon';
import { useSelector } from 'react-redux';
import API from '@pi/api';
import OrderBy from '../OrderBy';
import CalendarIcon from '@stracker/customicons/CalendarIcon';
import UserIcon from '@stracker/customicons/UserIcon';
import CotationIcon from '@stracker/customicons/CotationIcon';

const FilterBarStyled = React.memo(
	({
		t,
		selectTop,
		displaySelectsBottom,
		setDisplaySelectsBottom,
		page,
		filterData,
		setFilterData,
		filtersList,
		setFiltersList,
		orderValue,
		setOrderValue,
		setFilters
	}) => {
		const theme = useTheme();
		const [customerFilterList, setCustomerFilterList] = useState([]);
		const [customerList, setCustomerList] = useState([]);
		const user = useSelector(({ auth }) => auth.user);

		useEffect(() => {
			API.post('/customer', {
				action: 'listlight',
				idConnectedUser: user.id
			}).then(response => setCustomerList([...response.data]));
			// eslint-disable-next-line
		}, []);

		useEffect(() => {
			let customerFilterListCpy = [];
			// eslint-disable-next-line
			let existingCustomer =
				filtersList &&
				// eslint-disable-next-line
				filtersList.map(filter => {
					if (filter.type === 'customer') return filter.idCustomer;
				});
			// 51, 190 et 191, sont les comptes DEMO et TEST en PROD
			customerList &&
				customerList.forEach(customer => {
					if (customer.idCustomer !== 51 && customer.idCustomer !== 190 && customer.idCustomer !== 191)
						customerFilterListCpy.push({
							customerName: customer.customerName,
							idCustomer: customer.idCustomer,
							display: existingCustomer && existingCustomer.includes(customer.idCustomer) ? true : false
						});
				});
			setCustomerFilterList([...customerFilterListCpy]);
			// eslint-disable-next-line
		}, [customerList]);

		useEffect(() => {
			if (filtersList) parseRequest();
			// eslint-disable-next-line
		}, [filtersList]);

		const parseRequest = () => {
			let filters = {
				searchText: '',
				idCustomer: [],
				startDate: '',
				arrivalETA: '',
				country: [],
				countryEnd: [],
				typeTransport: '',
				transportMeans: '',
				idOps: []
			};
			filtersList &&
				filtersList.forEach(filter => {
					if (filter.type === 'customer') {
						filters.idCustomer.push(filter?.idCustomer);
					} else if (filter.type === 'transportType') {
						if (page !== 'cotation') filters.typeTransport = filter?.name;
						if (page === 'cotation') filters.transportMeans = filter?.name;
					} else if (filter.type === 'startCountry') {
						filters.country.push(filter?.value?.toLowerCase());
					} else if (filter.type === 'endCountry') {
						filters.countryEnd.push(filter?.value?.toLowerCase());
					} else if (filter.type === 'startDate') {
						filters.startDate = filter?.value;
					} else if (filter.type === 'endDate') {
						filters.arrivalETA = filter?.value;
					}
				});
			setFilters(filters);
		};

		const removeAllClient = () => {
			let filtersListCpy = filtersList;
			let i = filtersList.length - 1;
			while (i >= 0) {
				if (filtersList[i].type === 'customer') {
					filtersListCpy.splice(i, 1);
				}
				i--;
				setFiltersList([...filtersListCpy]);
			}

			let customerFilterListCpy = customerFilterList;
			customerFilterListCpy.forEach(client => {
				client.display = false;
			});
			setCustomerFilterList([...customerFilterListCpy]);
		};

		const removeFilter = index => {
			let filtersListCpy = filtersList;
			filtersListCpy.splice(index, 1);
			setFiltersList([...filtersListCpy]);
		};

		const clientNumber = () => {
			let ret = 0;
			filtersList &&
				// eslint-disable-next-line
				filtersList.map((data, i) => {
					if (data.type === 'customer') {
						ret++;
					}
				});
			return ret;
		};

		const removeAllFilters = () => {
			let filtersListCpy = filtersList;
			filtersListCpy.splice(0, filtersList.length);
			setFiltersList([...filtersListCpy]);
			removeAllClient();
		};

		return (
			<ContainerContentToolbarStyled>
				<div className="w-full">
					{page === 'cotation' && user.role !== 'superAdmin' ? (
						<div className="flex md:hidden">
							<CotationFilterDropDown filterData={filterData} setFilterData={setFilterData} />
						</div>
					) : (page === 'transport' || page === 'cotation') &&
					  displaySelectsBottom &&
					  user.role === 'superAdmin' ? (
						<>
							<button
								type="button"
								onClick={() => setDisplaySelectsBottom(!displaySelectsBottom)}
								className="flex md:hidden items-center my-8"
							>
								<CircleMinusIcon />
								<p className="font-bold ml-5">{t('HIDE_FILTERS')}</p>
							</button>
						</>
					) : (
						page !== undefined && (
							<button
								type="button"
								onClick={() => setDisplaySelectsBottom(!displaySelectsBottom)}
								className="flex md:hidden items-center my-8"
							>
								{!displaySelectsBottom ? <CircleAddIcon /> : <CircleMinusIcon />}
								<p className="font-bold ml-5">
									{!displaySelectsBottom ? t('SHOW_FILTERS') : t('HIDE_FILTERS')}
								</p>
							</button>
						)
					)}
					<div
						className="flex flex-col justify-between md:items-center w-full sm:flex-row"
						style={{
							color: theme.palette.stracker.colorOutlined,
							justifyContent: !displaySelectsBottom ? 'flex-end' : ''
							//padding: !displaySelectsBottom ? '0' : ''
						}}
					>
						{selectTop.map((select, index) => {
							return (
								<div
									key={select.selectName}
									style={{ display: displaySelectsBottom ? '' : 'none' }}
									className="flex w-auto items-center mt-12 md:mt-0"
								>
									<p className="pr-8" style={{ color: theme.palette.stracker.contrastTextLight }}>
										{t('ORDER_BY')}
									</p>

									<OrderBy
										title="Référence"
										icon={<CotationIcon color={theme.palette.stracker.contrastTextDark} />}
										orderValue={orderValue}
										setOrderValue={setOrderValue}
									/>
									<OrderBy
										title="ETA"
										icon={<CalendarIcon />}
										orderValue={orderValue}
										setOrderValue={setOrderValue}
									/>
									{user.role === 'superAdmin' && (
										// Si pas admin Board on ajoute pas le filtre client
										<OrderBy
											title="Client"
											icon={<UserIcon color={theme.palette.stracker.contrastTextDark} />}
											orderValue={orderValue}
											setOrderValue={setOrderValue}
										/>
									)}
								</div>
							);
						})}
					</div>
					{(page === 'transport' || page === 'cotation' || page === 'history') && displaySelectsBottom && (
						<div className="mt-5 flex">
							<p className="pr-8" style={{ color: theme.palette.stracker.contrastTextLight }}>
								{t('FILTER_BY')}
							</p>
							<TransportTypeFilterDropDown filtersList={filtersList} setFiltersList={setFiltersList} />
							<CountrySelector type={'start'} filtersList={filtersList} setFiltersList={setFiltersList} />
							<CountrySelector
								type={'destination'}
								filtersList={filtersList}
								setFiltersList={setFiltersList}
							/>
							{user.role === 'superAdmin' ? (
								<ClientFilter
									filtersList={filtersList}
									setFiltersList={setFiltersList}
									customerFilterList={customerFilterList}
								/>
							) : null}
						</div>
					)}

					<div style={{ display: 'flex', marginTop: '10px' }}>
						{filtersList && filtersList.length > 0 ? (
							<div style={{ marginRight: '10px', cursor: 'pointer' }}>
								<CircleDeleteIcon
									onClick={() => {
										removeAllFilters();
									}}
								/>
							</div>
						) : null}
						{clientNumber() > 0 ? (
							<>
								<CircleMinusIcon onClick={() => removeAllClient()} className="cursor-pointer" />
								<p style={{ marginLeft: '5px', marginRight: '10px' }}>
									{clientNumber() >= 2 ? t('CLIENTS') : t('CLIENT')} : {clientNumber()}
								</p>
							</>
						) : null}

						{filtersList &&
							filtersList.map((data, i) => {
								return (
									<div
										key={i}
										style={{
											display: data.type !== 'customer' ? 'flex' : '',
											marginRight: data.type !== 'customer' ? '10px' : ''
										}}
									>
										{data.type !== 'customer' ? (
											<CircleMinusIcon
												onClick={() => removeFilter(i)}
												className="cursor-pointer"
											/>
										) : null}
										{data.type === 'transportType' ? (
											data.name === 'car' ? (
												<div className="flex" style={{ marginLeft: '8px' }}>
													<TruckIcon />
													<p style={{ marginLeft: '8px' }}>{t('CAR')}</p>
												</div>
											) : (
												<div className="flex" style={{ marginLeft: '8px' }}>
													<PlaneIcon /> <p style={{ marginLeft: '8px' }}>{t('PLANE')}</p>
												</div>
											)
										) : null}
										{data.type === 'startCountry' ? (
											<>
												<ArrowUpRight /> {data.value}
											</>
										) : data.type === 'endCountry' ? (
											<>
												<ArrowDownRight /> {data.value}
											</>
										) : null}
										<div
											style={{
												color: theme.palette.stracker.contrastTextDark,
												marginTop: '-1px',
												marginLeft: '2px'
											}}
										>
											{data.type === 'startDate'
												? t('FROM_FILTER')
												: data.type === 'endDate'
												? t('TO_FILTER')
												: null}
											{data.type === 'startDate' || data.type === 'endDate'
												? data.value.split('T')[0].split('-').reverse().join('/')
												: null}
										</div>
									</div>
								);
							})}
					</div>
				</div>
				<div
					className="flex items-start pt-10 w-0 md:w-full sm:pt-0 sm:w-auto sm:items-center justify-end"
					style={{
						color: theme.palette.stracker.contrastTextDark,
						marginBottom: 'auto',
						marginLeft: 'auto',
						paddingTop: '7px',
						paddingBottom: '7px'
					}}
				>
					{page === 'cotation' && user.role !== 'superAdmin' ? (
						<div className="hidden md:flex">
							<CotationFilterDropDown filterData={filterData} setFilterData={setFilterData} />
						</div>
					) : (page === 'transport' || page === 'cotation') &&
					  displaySelectsBottom &&
					  user.role === 'superAdmin' ? (
						<>
							<button
								type="button"
								onClick={() => setDisplaySelectsBottom(!displaySelectsBottom)}
								className="hidden md:flex items-center"
							>
								<CircleMinusIcon />
								<p className="font-bold ml-5">{t('HIDE_FILTERS')}</p>
							</button>
						</>
					) : (
						page !== undefined && (
							<button
								type="button"
								onClick={() => setDisplaySelectsBottom(!displaySelectsBottom)}
								className="hidden md:flex items-center"
							>
								{!displaySelectsBottom ? <CircleAddIcon /> : <CircleMinusIcon />}
								<p className="font-bold ml-5">
									{!displaySelectsBottom ? t('SHOW_FILTERS') : t('HIDE_FILTERS')}
								</p>
							</button>
						)
					)}
				</div>
			</ContainerContentToolbarStyled>
		);
	}
);

const ContainerContentToolbarStyled = styled.div`
	width: 100%;
	display: flex;
	color: ${props => props.theme.palette.stracker.contrastTextDark} !important;
	font-weight: 600 !important;
	border-bottom: solid 1px;
	border-color: ${props => props.theme.palette.stracker.colorOutlined};

	[id$='input'] {
		width: auto !important;
	}

	.DateRangePicker {
		padding-top: 10px;
		@media (min-width: 960px) {
			padding-top: 0;
		}
	}

	.DateRangePickerInput__withBorder {
		border-radius: 40px;
	}

	.DateInput {
		border-radius: 40px;
	}

	.DateInput_input {
		font-size: 16px;
		font-weight: 600;
		color: ${props => props.theme.palette.stracker.contrastTextDark};
		border-radius: 40px;
		border: 2px solid transparent;
		text-align: center;
		padding: 5px 10px;
	}

	.DateInput_input__focused {
		border: 2px solid ${props => props.theme.palette.stracker.contrastText};
	}

	.CalendarMonth_caption {
		color: ${props => props.theme.palette.stracker.contrastTextDark};
	}

	tr {
		border-bottom: 2px solid white;
		border-left: none;
		border-right: none;
		border-top: none;
	}

	.CalendarDay__default {
		border: none;
	}

	.CalendarDay__selected_span {
		background: ${props => props.theme.palette.primary.main}; //background
		color: white; //text
	}

	.CalendarDay__selected {
		background: ${props => props.theme.palette.stracker.contrastText};
		color: white;
	}

	.CalendarDay__selected:hover {
		background: ${props => props.theme.palette.stracker.contrastTextDark};
		color: white;
	}

	.CalendarDay__hovered_span:hover,
	.CalendarDay__hovered_span {
		background: red;
	}
	.CalendarDay__blocked_out_of_range {
		border: none;
	}
	.DateRangePickerInput_arrow_svg {
		margin: 0 10px;
	}
	[class$='indicatorContainer'] {
		color: ${props => props.theme.palette.stracker.contrastTextDark};
	}
`;

export default React.memo(FilterBarStyled);
