import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { TitleTabSettings } from '@stracker/components/title/TitleTabSettings';
import { ButtonStyled } from '@stracker/components/StyledComponents/ButtonStyled';
import ContractorsIcon from '@stracker/customicons/ContractorsIcon';

function AddressBookHeader({ pageRouter, setPageRouter }) {
	const theme = useTheme();
	const { t } = useTranslation('addressBook');

	const user = useSelector(({ auth }) => auth.user);

	return (
		<>
			<div className="w-full flex-col">
				<div className={clsx()} style={{ transition: 'margin-right 0.2s' }}>
					<TitleTabSettings disabledBorder style={{ justifyContent: 'space-between', alignItems: 'start' }}>
						<div className="flex">
							<ContractorsIcon color={theme.palette.stracker.contrastText} />
							<h4 style={{ fontSize: '18px' }}>{t('ADDRESS_BOOK')}</h4>
						</div>

						<div className="flex mr-6 md:mr-0">
							{pageRouter !== '' ? null : (
								<ButtonStyled
									type="button"
									className={clsx(
										user.occupation !== 'userAdmin' || user.occupation !== 'superAdmin'
											? null
											: 'hidden'
									)}
									style={{ transition: 'all 0.2s', padding: '7px 10px' }}
									onClick={() => setPageRouter('addContact')}
								>
									{t('ADD_CONTACT')}
								</ButtonStyled>
							)}
						</div>
					</TitleTabSettings>
				</div>
			</div>
		</>
	);
}

export default AddressBookHeader;
