import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse/core/formsy';
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from './store/actions';
import API from '@pi/api';

const useStyles = makeStyles(theme => ({
	avatar: {
		backgroundColor: '#D0EEE3',
		color: '#16A974'
	},
	button: {
		display: 'block',
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	dividerS: {
		backgroundColor: '#E0E8FC'
	},
	MboutonBoxV: {
		borderRadius: '8px',
		paddingBottom: '3px !important',
		minWidth: '100px',
		height: '34px'
	},
	hoverColor: {
		backgroundColor: '#fff'
	},
	hoverValidButton: {
		backgroundColor: '#074EE8'
	},
	'@global': {
		'.MuiInputBase-input': {
			paddingRight: '0 !important',
			width: '98%',
			borderRadius: '8px',
			backgroundColor: '#E0E8FC',
			color: '#023E65',
			fontWeigth: 'bold'
		},
		'.MuiOutlinedInput-input': {
			paddingTop: '8px',
			paddingBottom: ' 8px',
			fontWeight: '600'
		},
		'.MuiOutlinedInput-root': {
			border: 'none !important',
			borderRadius: '8px'
		},
		'.mbottomButtom': {
			width: '220px',
			textAlign: 'right',
			marginLeft: 'auto'
		},
		'.hoverColor:hover': {
			backgroundColor: '#074EE8',
			color: '#fff !important'
		},
		'hoverValidButton:hover': {
			backgroundColor: '#074EE8'
		},
		'.mbottomButtom .hoverValidButton:hover': {
			backgroundColor: '#074EE8'
		},
		'.notifAdd .MuiList-padding': {
			padding: 0
		},
		'.MuiTypography-root': {
			color: '#809EB2',
			fontWeight: '600'
		},
		'.MuiTypography-root.strackerGray': {
			color: '#023E65'
		},
		'.mbottomButtom .MuiButton-contained.Mui-disabled': {
			backgroundColor: '#83a7f3',
			color: '#fff'
		},
		'.mbottomButtom .bcancel': {
			paddingBottom: '4px',
			backgroundColor: '#fff',
			color: '#023E65',
			border: '1px solid #A8C1F7',
			borderRadius: '8px'
		}
	}
}));

function EditMembers(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('membres');

	const [defaultFormState, setDefaultFormState] = useState({
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		role: ''
	});

	const user = useSelector(({ auth }) => auth.user);
	const idConnectedUser = user.data.idUser;

	const [isFormValid, setIsFormValid] = useState(false);

	const handleChange = e => {
		const { name, value } = e.target;
		setDefaultFormState(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const { idEditMember } = props;

	useEffect(() => {
		const request = API.post('/user', {
			action: 'read',
			idUser: idEditMember
		});
		request.then(response => {
			console.log(response);
			setDefaultFormState({
				firstName: response.data.firstName ? response.data.firstName : '',
				lastName: response.data.lastName ? response.data.lastName : '',
				phone: response.data.phone ? response.data.phone : '',
				email: response.data.email ? response.data.email : '',
				role: response.data.role ? response.data.role : ''
			});
		});
		// eslint-disable-next-line
	}, []);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function backList(BackToList) {
		BackToList();
	}

	function handleSubmit(model) {
		model.idUser = idEditMember;
		model.idCustomer = user.data.idCustomer;
		model.idConnectedUser = idConnectedUser;
		console.log(model);

		dispatch(Actions.updateMember(model));
		props.cancel();
	}

	return (
		<>
			<div>
				<div className="mb-10 pb-20">
					<div className="float-left strackergrey">{t('MODIFMEMBER')}</div>
					<div className="clear-none" />
				</div>

				<div className="w-full">
					<Divider className={clsx(classes.dividerS, 'w-full')} />
				</div>

				<Formsy
					name="EditMember"
					onValidSubmit={handleSubmit}
					onValid={enableButton}
					onInvalid={disableButton}
					ref={props.formRef}
					className="flex flex-col justify-center w-full EditMember mt-28"
				>
					<div className="">
						<TextFieldFormsy
							className="w-full mb-16 bginputlog border-none"
							autoFocus
							type="firstName"
							name="firstName"
							placeholder={t('FIRSTNAME')}
							value={defaultFormState.firstName}
							onChange={handleChange}
							variant="outlined"
							required
							fullWidth
						/>
					</div>

					<div>
						<TextFieldFormsy
							className="w-full mb-16 bginputlog border-none"
							autoFocus
							type="lastName"
							name="lastName"
							value={defaultFormState.lastName}
							placeholder={t('NAME')}
							onChange={handleChange}
							variant="outlined"
							required
							fullWidth
						/>
					</div>

					<div>
						<TextFieldFormsy
							className="w-full mb-16 bginputlog border-none"
							autoFocus
							type="phone"
							name="phone"
							value={defaultFormState.phone}
							placeholder={t('TEL')}
							onChange={handleChange}
							variant="outlined"
							fullWidth
						/>
					</div>

					<div className="">
						<TextFieldFormsy
							className="w-full mb-16 bginputlog border-none"
							autoFocus
							type="email"
							name="email"
							value={defaultFormState.email}
							onChange={handleChange}
							variant="outlined"
							placeholder={t('EMAIL')}
							validations={{
								minLength: 4,
								isEmail: true
							}}
							validationErrors={{
								minLength: t('NBCARACTERES'),
								isEmail: t('EMAILNOVALID')
							}}
							required
							fullWidth
							disabled
						/>
					</div>

					<div className="">
						<TextFieldFormsy
							className="w-full mb-16 bginputlog border-none"
							autoFocus
							type="role"
							name="role"
							value={defaultFormState.role}
							onChange={handleChange}
							variant="outlined"
							placeholder={t('ROLE')}
							validations={{
								minLength: 2
							}}
							fullWidth
						/>
					</div>

					<div className="bottom-14 mbottomButtom">
						<Button
							variant="contained"
							color="primary"
							className={clsx(
								classes.mbuttonBack,
								classes.MboutonBoxV,
								'bcancel w-content rounded-8 mx-auto mt-0 mr-0 shadow-none normal-case text-white hoverColor hoverValidButton float-left'
							)}
							aria-label="CANCEL"
							type="cancel"
							onClick={() => backList(props.cancel)}
						>
							{t('CANCEL')}
						</Button>

						<Button
							variant="contained"
							color="primary"
							className={clsx(
								classes.MboutonBoxV,
								'w-content rounded-8 mx-auto mt-0 normal-case shadow-none text-white hoverValidButton'
							)}
							aria-label="UPDATE"
							disabled={!isFormValid}
							type="submit"
						>
							{t('UPDATE')}
						</Button>
					</div>
				</Formsy>
			</div>
		</>
	);
}

export default EditMembers;
