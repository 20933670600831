const locale = {
	ADD_CONTACT: 'Ajouter un contact',
	CREATE: 'Valider',
	CANCEL: 'Annuler',
	PLACEHOLDER_SEARCH: 'Rechercher',
	CONTACT: 'Contact',
	MODIFYCONTACT: 'Modifier un contact',
	IDENTITY: 'Identité',
	EMAIL: 'Adresse mail',
	PHONE: 'Téléphone',
	ROLE: 'Fonction',
	COMPANY: 'Société',
	FIRST_NAME: 'Prénom',
	LAST_NAME: 'Nom',
	UPDATE: 'Modifier',
	DELETE: 'Supprimer',
	CONFIRM_DELETE_CONTACT: "Voulez-vous vraiment supprimer ce contact du carnet d'adresse ?",
	ADDRESS_BOOK: 'Annuaire',
	NO_MAIL: "Pas d'adresse mail",
	MANDATORY_INPUT: 'Téléphone, prénom et nom sont obligatoires'
};

export default locale;
