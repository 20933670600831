const locale = {
	ADD_CONTACT: 'Add contact',
	CREATE: 'Validate',
	CANCEL: 'Cancel',
	PLACEHOLDER_SEARCH: 'Search',
	CONTACT: 'Contact',
	MODIFYCONTACT: 'Modify Contact',
	IDENTITY: 'Identity',
	EMAIL: 'Email',
	PHONE: 'Phone number',
	COMPANY: 'Company',
	FIRST_NAME: 'First name',
	LAST_NAME: 'Last name',
	ROLE: 'Role',
	UPDATE: 'Modify',
	DELETE: 'Delete',
	CONFIRM_DELETE_CONTRACTOR: 'Do you really want to delete this contractor ?',
	ADDRESS_BOOK: 'Phone Book',
	MANDATORY_INPUT: 'Phone, firstname and lastname are mandatory'
};

export default locale;
