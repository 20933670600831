import React from 'react';
import './Switch.css';
import { useTheme } from '@material-ui/core';

const Switch = ({ isOn, handleToggle }) => {
	const theme = useTheme();
	return (
		<>
			<input checked={isOn} onChange={handleToggle} className="react-switch-checkbox" type="checkbox" />
			<label
				style={{ background: isOn && theme.palette.stracker.contrastText }}
				className="react-switch-label"
				htmlFor={`react-switch-new`}
			>
				<span className={`react-switch-button`} />
			</label>
		</>
	);
};

export default Switch;
