import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Dialog, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FuseAnimate from '@fuse/core/FuseAnimate';
import Notifications from './notifications/Notifications';
import Membres from './membres/Membres';
import UsersIcon from '../../custom-icons/UsersIcon';
import BellIcon from '../../custom-icons/BellIcon';
// import MoonIcon from '../../custom-icons/MoonIcon';
import { updateUserSettings } from '../../../auth/store/userSlice';
import _ from '../../../../@lodash';
import Profile from './profile/Profile';
import ContractorsIcon from '@stracker/customicons/ContractorsIcon';
import AddressBook from './addressBook/AddressBook';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<FuseAnimate animation="transition.slideLeftIn" delay={100}>
			<ContainerMainTab
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}
			>
				{value === index && children}
			</ContainerMainTab>
		</FuseAnimate>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

const ParametersDialogMenu = ({ openDialogSettings, handleCloseDialogSettings, tabWanted }) => {
	const { t } = useTranslation('langues');
	const theme = useTheme();
	const displayMenuBurger = useMediaQuery('(max-width:768px)');
	const { contrastTextLight } = theme.palette.stracker;
	const dispatch = useDispatch();
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const user = useSelector(({ auth }) => auth.user);

	const [value, setValue] = React.useState(4);
	// eslint-disable-next-line
	const [darkMode, setDarkMode] = React.useState(false);
	const [photo, setPhoto] = React.useState('');
	const [initiales, setInitiales] = React.useState();
	const [burgerIsOpen, setBurgerIsOpen] = React.useState(false);

	const arrayTab = [
		{
			name: 'Profile',
			label: t('TITLEPROFIL'),
			component: (
				<Profile
					handleCloseDialogSettings={handleCloseDialogSettings}
					burgerIsOpen={burgerIsOpen}
					setBurgerIsOpen={setBurgerIsOpen}
					displayMenuBurger={displayMenuBurger}
				/>
			),
			logo:
				photo && photo !== '' ? (
					<AvatarStyled alt="user photo" src={photo} />
				) : (
					<AvatarStyled>{initiales}</AvatarStyled>
				)
		},
		{
			name: 'Notifications',
			label: t('TITLENOTIF'),
			component: (
				<Notifications
					handleCloseDialogSettings={handleCloseDialogSettings}
					burgerIsOpen={burgerIsOpen}
					setBurgerIsOpen={setBurgerIsOpen}
					displayMenuBurger={displayMenuBurger}
				/>
			),
			logo: <BellIcon />
		},
		{
			name: 'AddressBook',
			label: t('ADDRESS_BOOK'),
			component: <AddressBook />,
			logo: <ContractorsIcon size={20} />
		},
		{
			name: 'Membres',
			label: t('TITLEMEMBRE'),
			component: (
				<Membres
					handleCloseDialogSettings={handleCloseDialogSettings}
					burgerIsOpen={burgerIsOpen}
					setBurgerIsOpen={setBurgerIsOpen}
					displayMenuBurger={displayMenuBurger}
				/>
			),
			logo: <UsersIcon />
		}
	];

	useEffect(() => {
		let CurrentSettingsTab = localStorage.getItem('CurrentSettingsTab');
		if (CurrentSettingsTab && !tabWanted) {
			CurrentSettingsTab = parseInt(CurrentSettingsTab, 10);
			setValue(CurrentSettingsTab);
		} else if (tabWanted === 'profile') {
			localStorage.setItem('CurrentSettingsTab', 0);
			setValue(0);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!_.isEmpty(user.data)) {
			const getInitiales = user.data.displayName
				? user.data.displayName.match(/\b\w/g).join('').toUpperCase()
				: '';
			setInitiales(getInitiales);
			setPhoto(user.data.photoURL);
			setDarkMode(settings.theme.main !== 'charteGraph');
		}
	}, [user.data, settings.theme]);

	const handleChange = async (event, newValue) => {
		setValue(newValue);
		localStorage.setItem('CurrentSettingsTab', newValue);
	};
	// eslint-disable-next-line
	const handleChangeDarkMode = event => {
		setDarkMode(event.target.checked);

		const newSettings = {
			...settings,
			theme: {
				main: event.target.checked ? 'charteGraphDark' : 'charteGraph',
				navbar: event.target.checked ? 'charteGraphDark' : 'charteGraph'
			}
		};
		dispatch(updateUserSettings(newSettings));
	};

	const handleClickAway = () => {
		if (burgerIsOpen) setBurgerIsOpen(false);
	};

	const toggleMenu = e => {
		e.stopPropagation();
		setBurgerIsOpen(!burgerIsOpen);
	};

	return (
		<ThemeProvider theme={theme}>
			<DialogStyled
				open={openDialogSettings}
				onClose={handleCloseDialogSettings}
				fullWidth={displayMenuBurger}
				fullScreen={displayMenuBurger}
				maxWidth={!displayMenuBurger && 'lg'}
			>
				<ContainerSettings>
					<ClickAwayListener onClickAway={handleClickAway}>
						<ContainerLeftBarStyled
							className="flex flex-col h-full justify-between w-1/3"
							style={{
								backgroundColor: `${
									theme.palette.type === 'light'
										? theme.palette.primary.light
										: theme.palette.stracker.body
								}`
							}}
							burgerIsOpen={burgerIsOpen}
						>
							<div className={clsx(displayMenuBurger ? 'block' : 'hidden')}>
								<MenuIcon className="cursor-pointer" onClick={e => toggleMenu(e)} />
							</div>
							<div className={clsx(displayMenuBurger ? 'hidden' : 'flex justify-center w-full')}>
								<span className="text-16" style={{ color: contrastTextLight }}>
									{user.data.email}
								</span>
							</div>
							<ContainerTabsStyled
								value={value}
								onChange={handleChange}
								indicatorColor="primary"
								//variant="scrollable"
								scrollButtons="off"
								aria-label="scrollable auto tabs example"
								orientation="vertical"
								selectionFollowsFocus
							>
								{arrayTab.map(tab => {
									return (
										<TabStyled
											style={{
												display:
													(tab.name === 'Contractors' && user.data.idRole === 3) ||
													(tab.name === 'AddressBook' && user.data.idRole === 1)
														? 'none'
														: ''
											}}
											key={tab.name}
											label={
												<span
													style={{ display: displayMenuBurger && 'none' }}
													className="capitalize text-14 text-left"
												>
													{tab.label}
												</span>
											}
											icon={tab.logo}
											wrapped
											disableRipple
										/>
									);
								})}
							</ContainerTabsStyled>
							{/* Permet de switch entre light mode et dark mode mais on ne l'utilise pas */}
							{/* <div className="flex flex-col w-full">
								<div
									className="flex justify-around items-center"
									style={{ flexDirection: displayMenuBurger && 'column' }}
								>
									<MoonIcon />
									<p className={clsx(displayMenuBurger ? 'hidden' : 'block text-center')}>
										Dark Mode
									</p>
									<Switch
										edge="end"
										name="darkMode"
										onChange={handleChangeDarkMode}
										checked={darkMode}
										inputProps={{ 'aria-label': 'label-darkMode' }}
									/>
								</div>

								<span
									className={clsx(displayMenuBurger ? 'hidden' : '"ml-14 text-10"')}
									style={{ color: contrastTextLight, fontSize: '10px' }}
								>
									{t('DARKMODE_TXT')}
								</span>
							</div> */}
						</ContainerLeftBarStyled>
					</ClickAwayListener>
					{arrayTab.map((tab, index) => {
						if (value !== index) return null;
						if (tab.name === 'Contractors' && user.data.idRole === 3) return null;
						return (
							<TabPanel key={`tab-${tab.name}`} value={value} index={index}>
								{tab.component}
							</TabPanel>
						);
					})}
				</ContainerSettings>
			</DialogStyled>
		</ThemeProvider>
	);
};

const ContainerSettings = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	color: ${props => props.theme.palette.stracker.contrastTextDark};
	font-weight: 600;
	background-color: ${props => props.theme.palette.stracker.card};
`;

const ContainerLeftBarStyled = styled.div`
	padding: 14px 20px;
	@media screen and (max-width: 768px) {
		align-items: center;
		max-width: ${props => (props.burgerIsOpen ? '70' : '0')}px;
		padding: 15px 0;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 9999;
		overflow: hidden;
		transition: max-width 0.3s ease-in-out;
		box-shadow: 9px 4px 19px 1px rgba(0, 0, 0, 0.15);
	}
`;

const DialogStyled = styled(Dialog)`
	border-radius: 8px;
	[class*='MuiDialog-paperWidthLg'] {
		width: 65%;
		height: 65vh;
		overflow: hidden;
		@media screen and (min-width: 1250px) {
			width: 55%;
			height: 60vh;
		}
	}
`;

const ContainerTabsStyled = styled(Tabs)`
	height: auto;
	width: 100%;
	margin-bottom: auto;
	padding: 17px 0;

	[class*='MuiTabs-indicator'] {
		width: 3px;
		right: 0;
		bottom: 0;
		background-color: ${props => props.theme.palette.stracker.contrastText};
		display: none;

		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	[class*='MuiTabs-scroller'] {
		padding: 5px 15px 5px 0px;
		overflow-y: scroll !important;
		@media screen and (max-width: 768px) {
			margin-left: 0;
			padding: 0;
		}
	}
`;

const TabStyled = styled(Tab)`
	width: 92%;
	background-color: ${props => `${props.selected ? props.theme.palette.stracker.body : 'transparent'}`};
	box-shadow: ${props =>
		`${
			props.selected && props.theme.palette.type === 'light'
				? '0px 4px 4px rgb(7 78 232 / 15%)'
				: props.selected
				? '-5px -5px 8px #25292b, 5px 5px 8px #494f53'
				: 'none'
		}`};
	background: ${props =>
		`${props.selected && props.theme.palette.type === 'dark' && 'linear-gradient(315deg, #3b4043, #323639)'}`};
	padding: 5px 8px;
	border-radius: 8px;
	min-height: 45px;
	margin: 2px 0 2px 0px;
	@media screen and (max-width: 768px) {
		margin: 2px auto;
		width: auto;
		min-width: 50px;
		padding: 5px;
	}

	span {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: start;
		@media screen and (max-width: 768px) {
			justify-content: center;
		}
		svg {
			margin-bottom: 0 !important;
			margin-right: 24px;
			margin-left: 10px;
			@media screen and (max-width: 768px) {
				margin-right: 0 !important;
				margin-left: 0 !important;
			}
		}
		div {
			margin-bottom: 0 !important;
		}
	}
`;

const AvatarStyled = styled(Avatar)`
	width: 38px !important;
	height: 38px !important;
	color: #eda124;
	border: 2px solid #fff;
	font-size: 1.5rem;
	box-shadow: 0px 4px 4px rgb(7 78 232 / 15%);
	font-weight: 600;
	background-color: #fae3bd;
	margin-right: 13px;
	margin-bottom: 0 !important;
	@media screen and (max-width: 768px) {
		margin-right: 0;
	}
`;

const ContainerMainTab = styled.div`
	width: 100%;
	height: calc(100% - 20px);
	max-height: 100%;
	overflow: auto;
	padding: 0 10px 10px 20px;
	margin: 10px 5px 10px 0;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border: 2px solid ${props => props.theme.palette.stracker.colorOutlined};
		box-shadow: none;
	}
`;

export default ParametersDialogMenu;
