import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import _ from '@lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import MoreBtn from './inc/MoreBtn';
import * as Actions from './store/actions';
import SearchStyled from '@stracker/components/StyledComponents/SearchStyled';
import AddressBookForm from './AddressBookForm';

function AddressBookContent({ pageRouter, setPageRouter, selectedIdCustomer }) {
	const dispatch = useDispatch();
	const { t } = useTranslation('addressBook');
	const theme = useTheme();
	const user = useSelector(({ auth }) => auth.user);
	const addressBookList = useSelector(({ members }) => members.addressbook.state);
	const [selectedContact, setSelectedContact] = useState('');
	const [filteredData, setFilteredData] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (user.data.idCustomer) {
			dispatch(Actions.getAddressBook(user.data.idCustomer));
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		function getFilteredArray() {
			const arr = Object.keys(addressBookList).map(id => addressBookList[id]);
			return arr;
		}

		if (addressBookList) {
			setFilteredData(getFilteredArray());
		}
	}, [addressBookList]);

	return (
		<>
			{pageRouter === '' && (
				<div
					className="flex justify-between items-center border-b-1 border-t-1 py-10"
					style={{ borderColor: theme.palette.stracker.colorOutlined }}
				>
					<p style={{ color: theme.palette.stracker.contrastTextLight }}>{t('ADDRESS_BOOK')}</p>
					<SearchStyled
						searchValue={searchValue}
						setSearchValue={e => setSearchValue(e.target.value)}
						placeholder={`${t('PLACEHOLDER_SEARCH')}...`}
					/>
				</div>
			)}
			{pageRouter === 'addContact' ? (
				<AddressBookForm
					pageRouter="create"
					setPageRouter={setPageRouter}
					filteredData={filteredData}
					setFilteredData={setFilteredData}
					selectedIdCustomer={selectedIdCustomer}
				/>
			) : pageRouter === 'editContact' ? (
				<AddressBookForm
					pageRouter="update"
					setPageRouter={setPageRouter}
					selectedContact={selectedContact}
					filteredData={filteredData}
					setFilteredData={setFilteredData}
					selectedIdCustomer={selectedIdCustomer}
				/>
			) : (
				filteredData &&
				(filteredData.length > 0 ? (
					<ListStyled aria-label="main members List">
						{
							// eslint-disable-next-line
							filteredData.map((data, index) => {
								if (data.firstname.toLowerCase().includes(searchValue.toLowerCase()))
									return (
										<ListItem
											className="my-5 border-b-1"
											style={{ borderColor: theme.palette.stracker.colorOutlined }}
											key={index}
											alignItems="center"
											disableGutters
										>
											<ListItemAvatar>
												<AvatarStyled
													isadmin={data.roleName !== 'user' ? 'true' : 'false'}
													size={45}
													alt={data.firstname}
												>
													{data.firstname ? _.toUpper(data.firstname.slice(0, 1)) : null}
												</AvatarStyled>
											</ListItemAvatar>
											<ListItemText
												primary={
													<span className="font-semibold">
														{data?.firstname} {data?.lastname.toUpperCase()} {' @'}
														{data?.company}
													</span>
												}
												secondary={
													<span
														className="font-semibold text-12"
														style={{ color: theme.palette.stracker.contrastTextLight }}
													>
														<span>{data.email || t('NO_MAIL')}</span>
														<br />
														<span>+{data.phone}</span>
													</span>
												}
											/>

											<ListItemSecondaryAction
												style={{ color: theme.palette.stracker.contrastTextLight }}
												key={index}
												className="flex items-center"
											>
												<MoreBtn
													setPageRouter={setPageRouter}
													contactData={data}
													setSelectedContact={setSelectedContact}
													filteredData={filteredData}
													setFilteredData={setFilteredData}
												/>
											</ListItemSecondaryAction>
										</ListItem>
									);
							})
						}
					</ListStyled>
				) : null)
			)}
		</>
	);
}

const ListStyled = styled(List)`
	height: calc(100% - 75px);
	overflow: scroll;
	padding-top: 0;
	margin-top: 10px;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border: 2px solid ${props => props.theme.palette.stracker.colorOutlined};
		box-shadow: none;
	}
`;

const AvatarStyled = styled(Avatar)`
	width: ${props => (props.size ? props.size : 38)}px !important;
	height: ${props => (props.size ? props.size : 38)}px !important;
	color: ${props => (props.isadmin === 'true' ? props.theme.palette.stracker.contrastText : '#eda124')};
	border: 2px solid #fff;
	font-size: 1.5rem;
	box-shadow: 0px 4px 4px rgb(7 78 232 / 15%);
	font-weight: 600;
	background-color: ${props => (props.isadmin === 'true' ? '#A8C1F7' : '#FAE3BD')};
	margin-right: 13px;
	margin-bottom: 0 !important;
`;

export default AddressBookContent;
