import FuseUtils from '@fuse/utils';
import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes } = context;
		this.state = {
			accessGranted: true,
			routes
		};
	}

	componentDidMount() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted;
	}

	componentDidUpdate() {
		if (
			!this.state.accessGranted &&
			(this.props.location.pathname !== '/share' || !this.props.location.pathname.includes('share'))
		) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole } = props;
		const { pathname } = location;

		const matched = matchRoutes(state.routes, pathname)[0];

		return {
			accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true
		};
	}

	redirectRoute() {
		const { location, userRole, history } = this.props;
		const { pathname, state } = location;
		const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';
		if (pathname.includes('transport/transportDetails/') || pathname.includes('/cotations')) {
			localStorage.setItem('xav', pathname);
		}
		/*
        User is guest
        Redirect to Login Page
        */
		// http://localhost:3000/share?extref=3e556c7a4ea25b60b3fff2883fcae662 Asset Route
		// http://localhost:3000/share?extref=a961470c4e5d5e04b8dd7b7ac6281f83 Master Route
		// http://localhost:3000/share?extref=cca70a28931d5413af2e6e96473630d8 Transport Asset Route
		// http://localhost:3000/share?extref=f8f41738fb68566abebff38686fa73af Transport Route Access restricted
		// http://localhost:3000/share?extref=e326010d3540534fbfa5f74527161cb9 Transport All Access
		if (pathname === '/share' || pathname.includes('share')) {
			history.push({
				pathname: '/share',
				externalKey: location.search,
				state: { redirectUrl: location.search }
			});
		} else if (!userRole || userRole.length === 0) {
			history.push({
				pathname: '/login',
				state: { redirectUrl: pathname }
			});
		} else {
			/*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
			history.push({
				pathname: redirectUrl
			});
		}
	}

	render() {
		// console.info('Fuse Authorization rendered', accessGranted);
		return this.state.accessGranted ||
			this.props.location.pathname === '/share' ||
			this.props.location.pathname.includes('share') ? (
			<>{this.props.children}</>
		) : null;
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role
	};
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
